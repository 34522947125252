.game-text-big {
  font-size: 6rem;
}

.stats-mt {
  margin-top: 0px;
}

@media screen and (max-width: 992px) {
  .game-text-big {
    font-size: 3rem;
  }
  .stats-mt {
    margin-top: 6rem;
  }
}

.admin-welcome-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 116px;
  line-height: 116px;
  padding-top: 134px;
  color: #ffd600;
}
.home-no-game-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 96px;
  margin-bottom: 10px;
  color: #ffd600;
  z-index: 2;
}
.admin-home-button {
  background: #ffd600;
  border: 2px solid rgba(255, 214, 0, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  width: 165px;
  height: 55px;
  color: black;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 66px;
}
.mr-5 {
  margin-right: 5px;
}
.pointer {
  display: block;
}
.pointer:hover {
  cursor: pointer;
}
/* @media screen and (max-width: 1399px) {
  .home-no-game-text {
    font-size: 75px;
    line-height: 75px;
  }
}
@media screen and (max-width: 1024px) {
  .img-in-group {
    padding-top: 50px !important;
  }
  .img-how-to-play {
    width: 100%;
  }
  .box-content-title {
    font-weight: 400 !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }
  .box-content-text {
    font-family: "Roobert";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;

    letter-spacing: 0.05em;
  }
}
@media screen and (max-width: 800px) {
  .admin-welcome-text {
    font-size: 50px;
    line-height: 60px;
  }
  .table-head {
    height: 3rem !important;
  }
  .title-main {
    flex-direction: column;
  }
}
@media screen and (max-width: 575px) {
  .home-no-game-text {
    font-size: 55px;
    line-height: 55px;
  }
}

@media screen and (max-width: 400px) {
  .mb-table-head {
    height: 3px;
  }
  .table-head {
    height: 1rem !important;
    vertical-align: unset !important;
  }
} */
.container-public,
.container-private {
  padding-left: 0;
  padding-right: 0;
}
.box-title {
  width: 70%;
}

.box-text-desc {
  width: 30%;
  margin-top: 80px;
}

.text-desc {
  /* width: 779px; */
  /* height: 78px; */

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: #ffffff;
  padding-top: 85px;
}

.box-step {
  position: relative;
}

.step-border-1 {
  position: absolute;
  top: 5px;
  left: 19px;
  width: 35%;
}
.step-border-3 {
  position: absolute;
  top: 1%;
  left: 14%;
  width: 35%;
}

.step-border-4 {
  position: absolute;
  top: 1%;
  left: 26%;
  width: 35%;
}

.step-border-5 {
  position: absolute;
  top: 1%;
  left: 38.7%;
  width: 35%;
}

.text-step-4 {
  position: absolute !important;
  top: 1%;
  left: 24%;
  width: 37%;
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.text-step-5 {
  position: absolute !important;
  top: 1%;
  left: 37%;
  width: 37%;
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.border-step {
  border-radius: 10px;
  z-index: 2;
  width: 36%;
  height: 73.5px;
  background: #ffd600;
  clip-path: polygon(0 0, 83% 0, 100% 100%, 0% 100%);
  margin-left: 2px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-step {
  font-family: Minecraft;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  color: #202226;
  line-height: 100%;
}

.text-step-1 {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 37%;
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.text-step-3 {
  position: absolute !important;
  top: 1%;
  left: 12%;
  width: 37%;
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.line-r-1-rm {
  position: absolute;
  transform: translate(18%, -104%);
  width: 25%;
}
.line-r-2-rm {
  position: absolute;
  transform: translate(11%, 342%);
  width: 45%;
}
.bg-r-3-rm {
  position: absolute;
  transform: translate(177%, 267%);
  width: 40%;
}
.line-r-5-rm {
  position: absolute;
  transform: translate(9%, 457%) scaleX(-1);
  width: 45%;
}
.br-r-4-rm {
  position: absolute;
  transform: translate(95%, 185%);
  width: 57%;
}
.bg-l-3-rm {
  position: absolute;
  transform: translate(0%, 344%);
  width: 44%;
}
.line-l-4-rm {
  position: absolute;
  transform: translate(165%, 358%);
  width: 35%;
}
.line-l-2-rm {
  position: absolute;
  transform: translate(139%, -118%);
  width: 40%;
}
.bg-l-1-rm {
  position: absolute;
  transform: translate(-37%, -118%);
  width: 35%;
}
.bg-l-2-rm {
  position: absolute;
  transform: translate(19%, 563%);
  width: 40%;
}
.line-l-3-rm {
  position: absolute;
  transform: translate(165%, 596%);
  width: 35%;
}
.bg-r-2-rm {
  position: absolute;
  transform: translate(6%, 316%);
  width: 90%;
}

.box-content {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
}

.box-content-item {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.box-content-title {
  font-family: Minecraft;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  color: #ffd600;
}

.table-border {
  border: 1px solid #ffd600;
}

.table-border-2 {
  border: 1px solid #ffd600;
}

.table-border-2 tr {
  padding: 0px;
  line-height: 2rem;
}

/* .table-responsive {
  max-height: 50px;
} */

.td-w {
  width: 13%;
}

.table-text {
  font-family: Robert;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100px;
  text-align: center;
  color: #ffffff;
}
.table-text-htp {
  line-height: 80px;
  font-weight: 500;
}
.table-text-head {
  font-family: Robert;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
th {
  font-weight: 700 !important;
}

.table-head {
  position: relative !important;
  border: none !important;
  height: 7rem;
  vertical-align: middle !important;
}

.img-white-page {
  width: 100% !important;
}

.table-head tr {
  border: none;
}

/* .table-head tr th {
  border: 1px solid #ffd600;
} */
.th-bg {
  position: relative;
}
.bg-table-head {
  position: absolute;
  width: 400%;
  height: 150%;
  bottom: -1px;
  left: 0;
  z-index: -1;
}

.bg-table-head-wp-rp {
  position: absolute;
  width: 500%;
  height: 150%;
  bottom: -1px;
  left: 0;
  z-index: -1;
}

.bg-table-head-myk {
  position: absolute;
  width: 300%;
  height: 150%;
  bottom: -1px;
  left: 0;
  z-index: -1;
}

.box-table {
  margin-top: -24px;
}

table tbody {
  border-top-color: #ffd600 !important;
}

.text-step3 {
  position: absolute !important;
  top: 3px;
  left: 208px;
}

.box-content-step3 {
  padding-top: 50px;
}

.box-content-step5 {
  padding-top: 50px;
}

.text-step4 {
  position: absolute !important;
  top: 3px;
  left: 410px;
}

.box-content-step4 {
  padding-top: 200px;
}

.text-step5 {
  position: absolute !important;
  top: 3px;
  left: 615px;
}
.td-title {
  background: #ffd600 !important;
  color: #000000;
}

.td-title-top {
  border-bottom: 1px solid #000000 !important;
}
/* .table-distribution {
  margin-top: 300px;
} */
.instruction-white-page {
  /* padding-top: 100px; */
  /* padding-bottom: 200px; */
}

.container-box2-wp {
  margin-bottom: 184px !important;
}
.container-btn-wp,
.container-box2-wp-rp,
.content-2-wp-rp .container-box-wp-rp {
  margin-bottom: 184px;
}

.table-white-page-rp .tb-rp {
  margin-top: 48px !important;
}

.text-instruction {
  font-family: Robert;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.content-background-whit-page {
  padding-top: 7%;
  padding-left: 3%;
}

.whitepaper .group-img {
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
  align-items: center;
}
.img-left-wp {
  position: absolute;
  width: 109% !important;
  height: auto !important;
}

.content-background-whit-page-game {
  padding-top: 40px;
}

.smite-img {
  transform: scaleX(-1) rotate(5.24deg);
}
.smite-img-step {
  transform: matrix(-1, 0.09, 0.09, 1, 0, 0);
}

.table-white-page {
  margin-top: 200px;
}

.table-white-page-rp {
  /* margin-top: 20%; */
}
.wp-wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 355.23px;
  background: url("../../assets/white-paper/bg-header-wp.png");
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  background-position: center;
  background-size: contain;
  z-index: -1;
  top: 0px;
}

.group-img {
  position: relative !important;
}
/* .rectangle {
  position: absolute !important;
  top: 0px;
  width: 100%;
} */
.img-in-group {
  width: 100%;
  padding-top: 0px;
}
.img-top-wp {
  position: absolute;
  top: 0;
}
.bg-line-wp {
  z-index: -1;
  right: -12%;
  top: -82%;
  width: 130%;
}
.img-right-wp {
  position: absolute;
  right: -84px;
  margin-top: -96px;
}
.bg-spare-wp {
  width: unset;
  left: 15%;
}

.txt-box-button-white-page {
  padding-left: 50px;
}

.btn-white-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 20px;
  gap: 10px;
  width: 288px;
  height: 55px;
  background: rgba(255, 214, 0, 0.1);
  border: 2px solid rgba(255, 214, 0, 0.25);
  border-radius: 6px;
}
.txt-btn-white-page {
  font-family: Robert;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffd600;
}

.button-white-page {
  /* padding-top: 100px; */
}

.txt-road-map {
  font-family: Robert;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}
.box-txt-road-map {
  padding-top: 7%;
}
.text-title-step-4-road-map {
  line-height: 3rem;
}
.txt-road-map-hidden {
  display: none;
}
.box-road-map-3 {
  transform: translateY(-45%);
}
.title-bottom-road-map {
  width: 50% !important;
}
.text-box-content-title {
  font-family: MineCraft;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #ffd600;
}
.counter-key {
  width: 100%;
  height: 55px;
  border: 1px solid #ffd600;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-counter-key {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btn-buy-key {
  height: 55px;
  width: 100%;
  background: #ffd600;
  border: 2px solid rgba(255, 214, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
}
.container-btn-buy-key {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0e0f11;
}
.number-counter {
  width: 65px;
  height: 24px;

  font-family: Minecraft;
  font-style: normal;
  font-weight: 400;
  font-size: 29.1461px;
  line-height: 29px;
  text-align: center;
}

.container-table-mint-key {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-mint-key {
  width: 70%;
  text-align: center;
}
.txt-table-head {
  font-family: Robert;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  color: #000000;
}
.txt-table-body {
  font-family: Robert;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
  color: #ffffff;
}
.bg-yellow {
  background-color: #ffd600 !important;
  border: none !important;
}
.custom-border-l {
  border-left: none !important;
}
.custom-border-r {
  border-right: none;
}
.custom-border-bottom {
  border-bottom: none !important;
}
.title-main {
  display: flex;
  flex-direction: row;
}
.title-mint {
  padding-top: 50px;
}

.border-key {
  z-index: -1;
  width: 320px;
  height: 345px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: relative !important;
}
.key-gold-mint {
  position: absolute !important;
  width: 80%;
  filter: drop-shadow(9px 4px 114px rgba(255, 218, 163, 0.7));
  transform: rotate(0.35deg) scaleX(-1) scale(2.5) translateY(-4%);
}
.key-bronze-mint {
  position: absolute !important;
  width: 80%;
  filter: drop-shadow(9px 4px 114px rgba(255, 218, 163, 0.7));
  transform: rotate(0.35deg) scaleX(-1) scale(2.3) translateY(-6%)
    translateX(-8%);
}

.key-text {
  width: 97px;
  height: 24px;

  font-family: Minecraft;
  font-style: normal;
  font-weight: 400;
  font-size: 29.1461px;
  line-height: 29px;
  padding-bottom: 5%;
}
.table-mint-your-key {
  margin-top: 20%;
}
.key-text-gold {
  color: #ffd600;
}
.text-title-gold {
  transform: translateX(-15%);
}
.text-title-bronze {
  transform: translateX(5%);
}
.key-text-bronze {
  color: #cd7f32;
}
.key-text-silver {
  color: #c0c0c0;
}
.row-title-key {
  height: 60%;
}

.bg-line-small-wp {
  display: none;
}

.title-key-box {
  height: 26% !important;
  width: 100%;
  position: absolute !important;
  bottom: 0;
  left: 0;
}
.text-title-key {
  text-align: center;
  transform: scaleX(-1);
}
.supply-key {
  width: unset !important;
  padding-top: 10px;
}
.x {
  font-size: 20px;
}
.line-key {
  border-left: 1px solid #ffffff;
}
.container-how-to-play {
  /* overflow: hidden; */
}
.border-rp-small {
  display: none;
}
.table-white-page td {
  border-left: none !important;
  border-right: none !important;
}

.bg-header-square-wp {
  position: absolute;
  top: -87%;
  right: 101%;
  width: 40%;
  z-index: 2;
}
.bg-header-square-group-wp {
  position: absolute;
  top: -87%;
  right: 68%;
  width: 100%;
}
.img-header-wp {
  top: -103%;
  right: 48%;
  width: 120%;
  height: 64%;
  z-index: -1;
}
.container-title-myk {
  justify-content: unset;
}

.key-box-road-map {
  width: 100%;
}

.border-key-road-map {
  width: 100%;
  height: 445px;
}
.road-map-2 {
  transform: translateY(-40%);
  width: 100%;
}
.step-right-road-map {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
}

.box-road-map {
  position: relative;
}

.box-road-map.right {
  position: relative;
}

.step-left-road-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
}
/* .box-road-map-2 {
  transform: translateY(-30%);
}
.box-road-map-4 {
  transform: translateY(-80%);
}
.box-road-map-5 {
  transform: translateY(-100%);
}
.box-road-map-6 {
  transform: translateY(-130%);
}
.box-road-map-7 {
  transform: translateY(-150%);
} */
.box-road-map-8 {
  /* transform: translateY(-773%); */
  padding-left: 5%;
  padding-right: 15%;
}

.btn-mint-menu {
  width: 268px;
  height: 134px;
}
.step-text {
  font-family: MineCraft;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  color: #0f0d05;
}
.step-text-left {
  position: absolute;
  top: 2%;
  left: 3%;
  z-index: 2;
}
.step-text-right {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 2;
}
.coin-road-map {
  position: relative;
  height: 100%;
  width: 100%;
}
.img-small-coin-road-map {
  position: absolute;
  width: 50%;
  left: 10%;
  top: 0;
}
.img-large-coin-road-map {
  position: absolute;
  right: 10%;
  width: 50%;
  top: 0;
}
.bg-step-2-road-map {
  width: 70%;
  position: absolute;
  right: 15%;
  top: 0;
  z-index: -1;
}
.img-step-road-map {
  position: absolute;
  height: 55%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-complete {
  position: absolute;
  top: -4%;
  left: -5%;
  width: 32%;
}
.img-step-1-road-map {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.img-step-2-road-map {
  transform: scale(0.9);
}

.text-step-road-map {
  position: absolute;
  height: 45%;
  width: 100%;
  bottom: 0;
  padding-left: 8%;
  padding-right: 13%;
}
.text-step-right-road-map {
  position: absolute;
  height: 45%;
  width: 100%;
  bottom: 0;
  padding-left: 13%;
  padding-right: 8%;
}
.footer-step-road-map {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.footer-step-road-map img {
  width: 100%;
}
.text-desc-step-road-map {
  font-family: Robert;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #ffffff;
}
.line-step-road-map {
  height: 1px;
  background-color: #ffffff;
}
.text-phrase-step-road-map {
  padding-top: 8%;
  font-family: MineCraft;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.text-title-step-road-map {
  font-family: MineCraft;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  color: #ffd600;
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.bg-r-1-rm {
  position: absolute;
  width: 50%;
  transform: translate(122%, -113%);
}
.txt-final-road-map-hidden {
  display: none;
}

.border-small-rm {
  display: none;
}
.step-right-road-map-rm {
  display: none;
}
.container-content-title {
  padding-right: 50%;
}
.container-step-road-map {
  height: 100%;
}
.table-distribution {
  margin-top: 20%;
}
.td-w-htp {
  width: 11%;
}
.font-size-rank-htl {
  font-size: 20px !important;
}
.tb-w {
  width: 100%;
}
.img-header-wp-small {
  display: none;
}

.table-text-head-rm {
  font-size: 26px;
}
.table-text-rm {
  font-size: 20px;
}
.th-wp {
  width: 20%;
}
.th-htl {
  width: 25%;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 0px;
}
.table-bordered.all-border > :not(caption) > * > * {
  border-width: 1px;
}
.tbl-head-myk {
  width: calc(100% / 3);
}
.tbl-head-myk-first {
  position: relative;
}
@media screen and (max-width: 1440px) {
  .bg-table-head {
    left: -1px;
    width: calc(400% + 1px);
  }
  .bg-table-head-wp-rp {
    width: calc(500% + 2px);
    left: -1px;
  }
  .bg-table-head-myk {
    width: calc(300% + 2px);
    left: -1px;
  }
  .box-width-content-step1,
  .box-width-content-step2,
  .box-width-content-step3,
  .box-width-content-step4,
  .box-width-content-step5 {
    width: 448px !important;
  }
  .box-width-content-step3 {
    padding-top: 0 !important;
  }
  .box-img-step3 img {
    width: 554.85px;
  }
  .table-distribution-how-to-play {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .table-distribution {
    margin-top: unset;
  }
  .container-step-road-map {
    height: 580vh;
  }
  .box-road-map-8 {
    transform: translateY(-644%);
    padding-left: 5%;
    padding-right: 5%;
  }
  .bg-r-1-rm {
    transform: translate(131%, -113%);
  }
  .bg-l-1-rm {
    transform: translate(-44%, -118%);
  }
  .bg-r-3-rm {
    transform: translate(188%, 267%);
  }
  .br-r-4-rm {
    transform: translate(102%, 185%);
  }
  .text-title-step-road-map {
    padding-top: 2%;
    padding-bottom: 4%;
  }

  .text-step3 {
    left: 149px;
  }
  .text-step4 {
    left: 299px;
  }
  .text-desc {
    letter-spacing: unset;
    padding-top: 100px;
  }
  .bg-line-wp {
    right: -10%;
    top: -38%;
  }
  .text-instruction {
    line-height: unset;
  }
  .content-background-whit-page {
    padding-top: 3%;
    padding-left: unset;
  }
  .content-1-wp-rp {
    /* padding-top: 5%; */
  }
  .img-header-wp {
    height: unset;
    top: -70%;
    width: 140%;
    right: 45%;
  }
  .content-1-wp {
    margin-top: 50px;
  }
  .img-right-wp {
    right: -16%;
  }
  .border-key-road-map {
    height: 345px;
  }
  .text-phrase-step-road-map {
    padding-top: 4%;
  }
  .table-white-page-rp {
    /* margin-top: unset; */
  }
}
@media screen and (max-width: 1399px) {
  .home-no-game-text {
    font-size: 75px;
    line-height: 75px;
  }
}
@media screen and (max-width: 1368px) {
  .box-road-map-8 {
    transform: translateY(-614%);
  }
  .text-title-step-road-map {
    font-size: 32px;
    line-height: 32px;
    padding-top: 3%;
    padding-bottom: 5%;
  }
  .box-width-content {
    padding-top: 5%;
  }
}
@media screen and (max-width: 1315px) {
  .box-width-content {
    width: 70% !important;
  }
  .box-content-title {
    font-weight: 400 !important;
    font-size: 36px !important;
    line-height: 36px !important;
  }
}
@media screen and (max-width: 1279px) {
  .container-road-map {
    padding-left: 47px;
    padding-right: 47px;
  }
  .menu-item {
    font-size: 14px;
  }
  .bg-r-3-rm-rp {
    transform: translate(182%, -21%);
  }
  .box-road-map-8 {
    transform: translateY(-574%);
  }
  .br-r-4-rm {
    transform: translate(107%, 185%);
  }
}
@media screen and (max-width: 1279px) {
  .container-public,
  .container-private {
    padding-left: 47px;
    padding-right: 47px;
  }
  .content-background-whit-page {
    padding-right: 1%;
    padding-top: 2%;
  }

  .whitepaper .img-left-wp {
    width: 122% !important;
  }
  .text-instruction {
    font-size: 14px;
  }

  .container-box2-wp {
    margin-bottom: 94px !important;
  }
  .container-box2-wp-rp,
  .container-btn-wp,
  .container-box-wp-rp,
  .content-2-wp-rp {
    margin-bottom: 94px;
  }
}
@media screen and (max-width: 1232px) {
  .box-road-map-8 {
    transform: translateY(-554%);
  }
}
@media screen and (max-width: 1199px) {
  .mx-4r {
    margin-left: 67px;
    margin-right: 67px;
  }
  .container-public,
  .container-private {
    padding-left: 47px;
    padding-right: 47px;
  }
}
@media screen and (max-width: 1180px) {
  .container-step-road-map {
    height: 450vh;
  }
  .text-title-step-road-map {
    font-size: 32px;
    line-height: 32px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .box-road-map-8 {
    transform: translateY(-600%);
  }
}
@media screen and (max-width: 1024px) {
  .container-public,
  .container-private {
    padding-left: 47px;
    padding-right: 47px;
  }
  .wp-wrapper::before {
    height: 241.4px;
  }
  .table-white-page-rp .tb-rp {
    margin-top: 32px !important;
  }
  .whitepaper .img-left-wp {
    height: 100% !important;
  }
  .table-text-head-rm {
    font-size: 19px;
  }
  .table-text-rm {
    font-size: 14px;
  }

  .table-text-htp {
    font-size: 12px;
  }
  .bg-table-head-wp-rp {
    left: -1px;
    width: calc(500% + 2px);
  }
  .bg-table-head {
    width: calc(400% + 2px);
  }
  .table-head {
    height: 5rem;
  }
  .content-2-wp-rp {
    margin-top: 0 !important;
  }
  .btn-white-page {
    height: 30px;
    width: 180px;
  }
  .txt-btn-white-page {
    font-size: 10px;
    line-height: 12px;
  }
  .step-border-1 {
    height: 10%;
  }
  .step-border-2-rp {
    height: 12%;
  }
  .step-border-3 {
    height: 11%;
  }
  .step-border-4 {
    height: 11%;
  }
  .step-border-5 {
    height: 11%;
  }
  .rectangle-large {
    height: 427px;
  }
  .bg-l-1-rm {
    transform: translate(-45%, -116%);
  }
  .br-r-4-rm {
    transform: translate(103%, 185%);
  }
  .bg-r-3-rm {
    transform: translate(189%, 256%);
  }
  .box-txt-road-map {
    width: 62%;
  }
  .box-img-step3 img {
    width: 554.85px;
  }
  .container-road-map {
    padding-left: 47px;
    padding-right: 47px;
  }
  .img-complete {
    display: none;
  }
  .line-road-map-rp {
    width: 93%;
  }
  .border-key-road-map {
    height: 270px;
  }
  .key-text {
    font-size: 20px;
  }
  .title-key-gold-road-map {
    transform: translateX(-25%);
  }
  .title-key-silver-road-map {
    transform: translateX(-17%);
  }
  .title-key-bronze-road-map {
    transform: translateX(-13%);
  }
  .font-size-rank-htl {
    font-size: unset !important;
  }
  .t-w-htp {
    width: 11%;
  }
  .container-step-road-map {
    height: 420vh;
  }
  .container-content-title {
    padding-right: 52%;
  }
  .box-road-map-8 {
    transform: translateY(-719%);
    padding-right: unset;
  }
  .text-box-content-title {
    font-size: 24px;
    line-height: 24px;
  }
  .line-r-5-rm {
    transform: translate(7%, 525%) scaleX(-1);
    width: 40%;
  }

  .bg-r-1-rm {
    width: 80%;
    transform: translate(45%, -113%);
  }

  .step-text {
    font-size: 32px;
    line-height: 32px;
  }
  .text-desc-step-road-map {
    line-height: 19px;
    font-weight: 200;
    font-size: 14px;
  }
  .text-title-step-4-road-map {
    padding-right: 10%;
  }
  .text-step-right-road-map {
    height: 50%;
  }
  .txt-road-map-rp {
    display: none;
  }
  .txt-road-map-hidden {
    display: unset;
  }
  .txt-final-road-map-rp {
    padding-top: unset !important;
  }
  .text-title-step-road-map {
    font-size: 32px;
    line-height: 32px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .text-phrase-step-road-map {
    font-size: 12px;
    padding-top: 4%;
  }

  .img-in-group {
    padding-top: 50px !important;
  }
  .img-how-to-play {
    width: 87%;
  }
  .box-content-title {
    font-weight: 400 !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }
  .box-content-text {
    font-family: Robert;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;

    letter-spacing: 0.05em;
  }
  .text-step3 {
    left: 120px;
  }
  .text-step4 {
    left: 236px;
  }
  .text-desc {
    letter-spacing: unset;
    padding-top: 70px;
    font-size: 14px;
  }
  .box-main-title-rp {
    width: 45%;
  }

  .step-border-1 {
    top: 3px;
    left: 15px;
  }

  .box-width-content-step2 {
    width: 73% !important;
    padding-top: 10%;
  }

  .table-text {
    line-height: 71px;
  }
  .table-text-head {
    line-height: 50px;
  }
  /* .bg-table-head {
    height: 98px;
  } */
  .text-instruction {
    font-size: 14px;
    /* padding-top: unset !important ; */
  }
  .content-1-wp-rp {
    /* padding-top: 3%; */
  }
  .content-background-whit-page {
    padding-right: 1%;
    padding-top: 2%;
  }
  .table-distribution-wp {
    margin-top: unset !important;
  }
  /* .box-button-white-page {
    padding-top: 150px;
  } */
  .container-box-wp-rp {
    margin-top: unset !important;
  }
  .container-box2-wp-rp {
    /* margin-top: 70px !important; */
  }
  .img-header-wp {
    top: -65%;
    padding-top: unset !important;
  }
  .border-key {
    width: 100%;
  }
  .txt-table-head {
    font-size: 18px;
  }
  .txt-table-body {
    font-size: 14px;
  }
}
@media screen and (max-width: 1023px) {
  .container-step-road-map {
    height: unset;
  }
  .img-header-wp {
    top: -42%;
    padding-top: unset !important;
  }
  .box-title {
    width: 100%;
  }
  .txt-road-map-hidden {
    padding-right: 20%;
  }
  .txt-final-road-map-hidden {
    display: unset;
    padding-right: 20%;
  }
  .container-key-road-map-rp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .box-key-rp {
    width: 40%;
    display: block;
  }
  .txt-final-road-map-rp {
    display: none;
  }
  .border-key-road-map {
    height: 333px;
  }
  .box-key-silver-rp {
    margin: 5rem auto;
  }
  .box-content-title {
    font-size: 28px !important;
  }
  .container-step-road-map {
    max-height: unset;
  }
  .container-step-road-map-rp {
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .step-item-rp {
    align-self: unset !important;
  }
  .text-phrase-step-road-map {
    padding-top: 6%;
  }
  .text-title-step-road-map {
    padding-top: 5%;
    padding-bottom: 8%;
  }
  .bg-r-3-rm-rp {
    position: absolute;
    width: 26%;
    transform: translate(81%, -21%);
  }
  .bg-l-1-rm-rp {
    position: absolute;
    width: 70%;
    transform: translate(-95%, -106%);
  }
  .line-short-1-rm-rp {
    position: absolute;
    width: 15%;
    transform: translate(620%, -66%);
  }
  .line-short-2-rm-rp {
    position: absolute;
    width: 15%;
    transform: translate(-62%, -66%);
  }
  .line-1-rm-rp {
    position: absolute;
    width: 1%;
    transform: translate(-8431%, -100%);
  }
  .line-2-rm-rp {
    position: absolute;
    width: 1%;
    transform: translate(1305%, -105%);
  }
  .line-long-1-rm-rp {
    position: absolute;
    width: 10%;
    transform: translate(910%, 73%);
  }
  .line-long-2-rm-rp {
    position: absolute;
    width: 10%;
    transform: translate(-95%, 108%);
  }
  .bg-r-1-rm-rp {
    position: absolute;
    width: 60%;
    transform: translate(178%, -108%);
  }
  .bg-r-2-rm-rp {
    position: absolute;
    width: 40%;
    transform: translate(315%, -99%);
  }
  .text-step-road-map {
    padding-left: 8%;
    padding-right: 20%;
  }
  .text-step-right-road-map {
    padding-left: 8%;
    padding-right: 20%;
  }
  .step-right-road-map {
    width: 14%;
  }
  .step-left-3-road-map {
    width: 15% !important;
  }
  .step-text-left {
    top: 1%;
    left: 2%;
  }
  .step-text-right-rp {
    position: absolute;
    top: 1%;
    left: 2%;
    z-index: 2;
  }
  .step-text-right {
    top: 1%;
    right: 2%;
  }
  .step-right-road-map-rm {
    display: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: 14%;
    z-index: -1;
  }
  .text-step-right-road-map {
    height: 60%;
  }
  .step-2-right-road-map-rm {
    /* display: none !important; */
  }
  .step-left-road-map {
    position: absolute;
    width: 13%;
  }
  .box-road-map-2 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-3 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-4 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-5 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-6 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-7 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-8 {
    transform: unset;
    margin: 10% auto;
    width: 100% !important;
    padding-right: 20%;
    padding-bottom: 20%;
  }
  .img-step-road-map {
    height: 40%;
  }
  .text-step-road-map {
    height: 60%;
  }
  .border-small-rm {
    display: unset;
  }
  .border-large-rm {
    display: none;
  }
  .img-complete {
    display: none;
  }
  .bg-r-1-rm {
    display: none;
  }
  .line-r-1-rm {
    display: none;
  }
  .bg-l-1-rm {
    display: none;
  }

  .line-l-2-rm {
    display: none;
  }
  .bg-r-2-rm {
    display: none;
  }
  .bg-l-2-rm {
    display: none;
  }
  .line-l-3-rm {
    display: none;
  }
  .line-r-2-rm {
    display: none;
  }
  .bg-r-3-rm {
    display: none;
  }
  .bg-l-3-rm {
    display: none;
  }
  .line-l-4-rm {
    display: none;
  }
  .line-r-5-rm {
    display: none;
  }
  .br-r-4-rm {
    display: none;
  }
  .container-key {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .container-key-gold {
    justify-content: center !important;
  }
  .container-key-silver {
    padding-top: 6rem;
    padding-bottom: 6rem;
    justify-content: center !important;
  }
  .container-key-bronze {
    justify-content: center !important;
  }
}
@media screen and (max-width: 975px) {
  .tbl-rp-htl {
    min-width: 837px;
  }
  .tbl-rp-htl-border {
    overflow-x: auto;
  }
  .bg-r-3-rm-rp {
    transform: translate(49%, -21%);
  }
}
@media screen and (max-width: 960px) {
  .box-content-title {
    font-size: 28px;
    line-height: 28px;
  }
  .box-content-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 945px) {
  .menu-item {
    line-height: unset;
  }
  .bg-r-3-rm-rp {
    transform: translate(256%, -21%);
  }
}

@media screen and (max-width: 915px) {
  .bg-r-3-rm-rp {
    transform: translate(255%, -21%);
  }
  .step-border-1 {
    width: 34.5%;
  }
  .step-border-3 {
    left: 14.4%;
    width: 34.5%;
  }
  .step-border-4 {
    left: 26.5%;
    width: 34.5%;
  }
  .step-border-5 {
    left: 38.9%;
    width: 34.5%;
  }
  .step-border-2-rp {
    height: 11%;
  }
  .bg-table-head-wp-rp {
    width: 500%;
    left: 0px;
  }
}
@media screen and (max-width: 896px) {
  .bg-r-3-rm-rp {
    width: 26%;
    transform: translate(227%, -21%);
  }
}

@media screen and (max-width: 855px) {
  .hidden {
    display: none !important;
  }
  .box-width-content-step2 {
    width: 80% !important;
  }
}

@media screen and (max-width: 820px) {
  .bg-table-head-wp-rp {
    width: calc(500% + 2px);
    left: -1px;
  }

  .img-header-wp {
    top: -42%;
    padding-top: unset !important;
  }
  .box-title {
    width: 100%;
  }
  .txt-road-map-hidden {
    padding-right: 20%;
  }
  .txt-final-road-map-hidden {
    display: unset;
    padding-right: 20%;
  }

  .box-key-rp {
    width: 40%;
    display: block;
  }

  .border-key-road-map {
    height: 333px;
  }
  .box-key-silver-rp {
    margin: 5rem auto;
  }
  .box-content-title {
    font-size: 28px !important;
  }

  .text-phrase-step-road-map {
    padding-top: 6%;
  }
  .text-title-step-road-map {
    padding-top: 5%;
    padding-bottom: 8%;
  }
  .bg-r-3-rm-rp {
    position: absolute;
    width: 26%;
    transform: translate(153%, -21%);
  }
  .bg-l-1-rm-rp {
    width: 70%;
    transform: translate(-95%, -106%);
  }
  .line-short-1-rm-rp {
    width: 15%;
    transform: translate(620%, -66%);
  }
  .line-short-2-rm-rp {
    width: 15%;
    transform: translate(-62%, -66%);
  }
  .line-1-rm-rp {
    width: 1%;
    transform: translate(-8431%, -100%);
  }
  .line-2-rm-rp {
    width: 1%;
    transform: translate(1305%, -105%);
  }
  .line-long-1-rm-rp {
    width: 10%;
    transform: translate(910%, 73%);
  }
  .line-long-2-rm-rp {
    width: 10%;
    transform: translate(-95%, 108%);
  }
  .bg-r-1-rm-rp {
    width: 60%;
    transform: translate(178%, -108%);
  }
  .bg-r-2-rm-rp {
    width: 40%;
    transform: translate(315%, -99%);
  }
  .text-step-road-map {
    padding-left: 8%;
    padding-right: 20%;
  }
  .text-step-right-road-map {
    padding-left: 8%;
    padding-right: 20%;
  }
  .step-right-road-map {
    width: 14%;
  }
  .step-left-3-road-map {
    width: 15% !important;
  }
  .step-text-left {
    top: 1%;
    left: 2%;
  }
  .step-text-right-rp {
    position: absolute;
    top: 1%;
    left: auto;
    z-index: 2;
  }
  .step-text-right {
    top: 1%;
    right: 2%;
  }
  .step-right-road-map-rm {
    display: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: 14%;
    z-index: -1;
  }
  .text-step-right-road-map {
    height: 60%;
  }

  .step-left-road-map {
    position: absolute;
    width: 13%;
  }
  .box-road-map-2 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-3 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-4 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-5 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-6 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-7 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-8 {
    transform: unset;
    margin: 10% auto;
    width: 100% !important;
    padding-right: 20%;
    padding-bottom: 20%;
  }
  .img-step-road-map {
    height: 40%;
  }
  .text-step-road-map {
    height: 60%;
  }
}
@media screen and (max-width: 800px) {
  .admin-welcome-text {
    font-size: 50px;
    line-height: 60px;
  }
  .container-road-map {
    padding-left: unset;
    padding-right: unset;
  }
  .bg-r-3-rm-rp {
    transform: translate(205%, -21%);
  }
}
@media screen and (max-width: 768px) {
  .table-text-htp {
    line-height: 30px;
  }
  .border-key-road-map {
    height: 333px;
  }
  .container-road-map {
    padding-left: 4px;
    padding-right: 4px;
  }
  .container-step-road-map {
    max-height: unset;
  }
  .text-phrase-step-road-map {
    padding-top: 6%;
  }
  .text-title-step-road-map {
    padding-top: 5%;
    padding-bottom: 8%;
  }
  .bg-r-3-rm-rp {
    position: absolute;
    width: 26%;
    transform: translate(202%, -21%);
  }
  .bg-l-1-rm-rp {
    position: absolute;
    width: 70%;
    transform: translate(-80%, -106%);
  }
  .line-short-1-rm-rp {
    position: absolute;
    width: 15%;
    transform: translate(620%, -66%);
  }
  .line-short-2-rm-rp {
    position: absolute;
    width: 15%;
    transform: translate(-62%, -66%);
  }
  .line-1-rm-rp {
    position: absolute;
    width: 1%;
    transform: translate(-8431%, -100%);
  }
  .line-2-rm-rp {
    position: absolute;
    width: 1%;
    transform: translate(1305%, -105%);
  }
  .line-long-1-rm-rp {
    position: absolute;
    width: 10%;
    transform: translate(910%, 73%);
  }
  .line-long-2-rm-rp {
    position: absolute;
    width: 10%;
    transform: translate(-95%, 108%);
  }
  .bg-r-1-rm-rp {
    position: absolute;
    width: 60%;
    transform: translate(159%, -108%);
  }
  .bg-r-2-rm-rp {
    position: absolute;
    width: 40%;
    transform: translate(288%, -99%);
  }
  .text-step-road-map {
    padding-left: 8%;
    padding-right: 20%;
  }
  .text-step-right-road-map {
    padding-left: 8%;
    padding-right: 20%;
  }
  .step-right-road-map {
    width: 14%;
  }
  .step-left-3-road-map {
    width: 15% !important;
  }
  .step-text-left {
    top: 1%;
    left: 2%;
  }
  .step-text-right-rp {
    position: absolute;
    top: 1%;
    left: auto;
    z-index: 2;
  }
  .step-text-right {
    top: 1%;
    right: 2%;
  }
  .step-right-road-map-rm {
    display: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: 14%;
    z-index: -1;
  }
  .text-step-right-road-map {
    height: 60%;
  }
  .step-2-right-road-map-rm {
    /* display: none !important; */
  }
  .step-left-road-map {
    position: absolute;
    width: 13%;
  }
  .box-road-map-2 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-3 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-4 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-5 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-6 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-7 {
    transform: unset;
    margin: 10% auto;
  }
  .box-road-map-8 {
    transform: unset;
    margin: 10% auto;
    width: 100% !important;
    padding-right: 20%;
    padding-bottom: 20%;
  }
  .img-step-road-map {
    height: 40%;
  }
  .text-step-road-map {
    height: 60%;
  }
  .border-small-rm {
    display: unset;
  }
  .border-large-rm {
    display: none;
  }
  .img-complete {
    display: none;
  }
  .bg-r-1-rm {
    display: none;
  }
  .line-r-1-rm {
    display: none;
  }
  .bg-l-1-rm {
    display: none;
  }

  .line-l-2-rm {
    display: none;
  }
  .bg-r-2-rm {
    display: none;
  }
  .bg-l-2-rm {
    display: none;
  }
  .line-l-3-rm {
    display: none;
  }
  .line-r-2-rm {
    display: none;
  }
  .bg-r-3-rm {
    display: none;
  }
  .bg-l-3-rm {
    display: none;
  }
  .line-l-4-rm {
    display: none;
  }
  .line-r-5-rm {
    display: none;
  }
  .br-r-4-rm {
    display: none;
  }
  .container-key-road-map-rp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .box-key-rp {
    width: 40%;
    display: block;
  }
  .box-key-silver-rp {
    margin: 5rem auto;
  }
  .txt-final-road-map-rp {
    display: none;
  }
  .txt-final-road-map-hidden {
    display: unset;
    padding-right: 20%;
  }
  .txt-road-map-hidden {
    padding-right: 20%;
  }
  .div-final-road-map-hidden {
    height: 5rem;
  }
  .home-no-game-text {
    font-size: 55px;
    line-height: 55px;
  }
  .title-main {
    flex-direction: column;
  }
  .box-content-item {
    padding-top: 23px;
  }
  .box-img-step1 {
    padding-top: unset;
  }

  .text-desc {
    width: 100%;
    height: unset !important;
  }

  .box-width-content {
    width: 80% !important;
  }
  .border-step {
    clip-path: polygon(0 0, 83% 0, 100% 100%, 0% 100%);
    width: 416px;
  }
  .text-step3 {
    left: 2px;
  }
  .text-step4 {
    left: 2px;
  }
  .box-content3 {
    height: 90% !important;
  }
  .box-content-step3 {
    padding-top: 80px;
  }
  /* .box-title {
    width: 100%;
  } */
  .text-step {
    font-size: 1.5rem;
  }
  .hidden {
    display: none;
  }
  .box-content-text-step-2 {
    padding-top: 0.5rem !important;
  }
  .img-header-wp {
    width: 57%;
    left: 26%;
    top: -54%;
    z-index: -1;
  }
  .col-box-wp-rp {
    width: 100%;
    height: 550px;
  }
  .container-box-wp-rp {
    display: flex;
    flex-direction: column-reverse;
  }
  .container-box2-wp-rp {
    display: flex;
    flex-direction: column;
  }

  .img-in-group {
    padding-top: unset !important;
  }
  .instruction-white-page {
    /* padding-right: 12%; */
    padding-left: 12%;
  }
  .bg-line-wp {
    display: none;
  }
  .bg-line-small-wp {
    display: unset;
    z-index: -1;
    top: -10%;
  }
  .img-left-wp {
    top: -30%;
  }
  /* .box-button-white-page {
    padding-top: unset;
  } */
  .container-box2-wp-rp {
    margin-top: unset !important;
  }
  .col-button-wp-rp {
    width: 100%;
    padding-left: 12%;
    padding-right: 12%;
  }
  .txt-box-button-white-page {
    padding-left: unset;
  }
  .img-right-wp {
    right: unset;
  }
  .col-box-wp-long-rp {
    margin-top: 200px;
    margin-bottom: 250px;
  }
  .container-btn-wp {
    margin-bottom: 50px;
  }
  .col-box2-wp-long-rp {
    margin-bottom: unset;
  }
  .table-distribution-wp {
    margin-bottom: 400px;
  }
  .table-distribution {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  .box-content-title {
    font-size: 28px !important;
  }
  .container-step-road-map-rp {
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .step-item-rp {
    align-self: unset !important;
  }
}
@media screen and (max-width: 767px) {
  .img-header-wp {
    top: -56% !important;
  }
}
@media screen and (max-width: 740px) {
  .box-road-map {
    width: 60% !important;
  }
  .bg-r-2-rm-rp {
    transform: translate(245%, -99%);
  }
  .bg-r-3-rm-rp {
    transform: translate(321%, -21%);
  }
  .tbl-wp {
    min-width: 690px;
  }
  .tbl-rp-wp {
    overflow-x: auto;
  }
  .bg-l-1-rm-rp {
    transform: translate(-80%, -97%);
  }
  .line-2-rm-rp {
    transform: translate(1305%, -96%);
  }
  .box-road-map-8 {
    padding-left: unset;
  }
  .bg-r-3-rm-rp {
    width: 50%;
    transform: translate(110%, -21%);
  }
}
@media screen and (max-width: 720px) {
  .bg-r-3-rm-rp {
    transform: translate(48%, -21%);
  }
  .box-road-map-8 {
    padding-left: unset;
  }
  .tb-rp {
    overflow-x: auto;
  }
  .tb-w {
    width: 690px;
  }
}
@media screen and (max-width: 680px) {
  .box-width-content {
    width: 90% !important;
  }
  .bg-r-3-rm-rp {
    transform: translate(133%, -21%);
  }
}
@media screen and (max-width: 667px) {
  .bg-l-1-rm-rp {
    position: absolute;
    width: 70%;
    transform: translate(-80%, -97%);
  }
  .step-border-1 {
    width: 34%;
  }
  .step-border-2-rp {
    height: 11%;
  }
  .step-border-3 {
    left: 14.5%;
    width: 34.5%;
  }
  .step-border-4 {
    left: 26.5%;
    width: 34.5%;
  }
  .step-border-5 {
    left: 39%;
    width: 34%;
  }
}
@media screen and (max-width: 640px) {
  .box-width-content-step2 {
    padding-top: unset;
  }
  .step-border-1 {
    height: unset;
  }
  .img-how-to-play {
    width: 87%;
    transform: translateY(-50%);
  }
  .box-content-text {
    font-size: 14px;
  }
  .box-content {
    display: flex;
    flex-direction: column-reverse !important;
    top: -150px;
  }
  .box-content-item {
    width: 100%;
    padding-top: 30px;
  }
  .border-rp-large {
    display: none;
  }
  .border-rp-small {
    display: unset;
  }
  .step-border-1 {
    top: 3px;
    left: 16px;
    width: 65%;
  }

  .text-step-1 {
    top: 3px;
    left: 0;
    width: 64%;
    height: 6%;
  }
  .text-step-3 {
    width: 44%;
    height: 5%;
  }
  .text-step-4 {
    top: 3px;
    left: 0;
    width: 64%;
    height: 6%;
  }

  .text-step-5 {
    top: 3px;
    left: 0;
    width: 64%;
    height: 6%;
  }
  .table-text {
    line-height: 56px;
  }
}
@media screen and (max-width: 630px) {
  .bg-r-3-rm-rp {
    transform: translate(131%, -21%);
    width: 40%;
  }
  .tbl-head-myk {
    padding-top: 5% !important;
  }
  .table-text-head-rm {
    font-size: 16px;
  }
  .bg-table-head-wp-rp {
    width: 500%;
    height: 100%;
  }
  .th-bg-text-rp {
    padding-top: 7% !important;
  }
  .bg-table-head {
    height: 100%;
  }
  .table-head {
    height: 3rem;
  }
  .bg-table-head-wp {
    transform: scaleX(-1);
  }

  .line-2-rm-rp {
    width: 0.8%;
    transform: translate(1305%, -105%);
  }
  .bg-l-1-rm-rp {
    width: 53%;
    transform: translate(-80%, -104%);
  }
  .line-short-1-rm-rp {
    width: 13%;
    transform: translate(689%, -66%);
  }
  .bg-r-1-rm-rp {
    width: 60%;
    transform: translate(135%, -108%);
  }
  .box-road-map {
    width: 70% !important;
  }
  .line-1-rm-rp {
    width: 0.8%;
    transform: translate(-10831%, -100%);
  }
  .line-short-2-rm-rp {
    width: 12%;
    transform: translate(-62%, -66%);
  }
  .box-key-rp {
    width: 55%;
  }
}
@media screen and (max-width: 575px) {
  .mx-4r {
    margin-left: 24px;
    margin-right: 24px;
  }
  .bg-r-3-rm-rp {
    transform: translate(40%, -21%);
    width: 40%;
  }
  .tbl-rp-htl {
    min-width: unset;
  }
  .tbl-rp-htl-border {
    overflow-x: unset;
  }
  .table-bordered {
    border: 0px;
  }
  .tbl-wp {
    overflow-x: unset !important;
  }
  .tbl-wp {
    min-width: unset !important;
  }
  .brd {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
  }
  .home-no-game-text {
    font-size: 55px;
    line-height: 55px;
  }
  .td-w {
    width: unset !important;
  }
  .td-w-htp {
    width: unset !important;
  }
}

@media screen and (max-width: 540px) {
  .img-header-wp {
    top: -49% !important;
  }

  .bg-r-3-rm-rp {
    transform: translate(107%, -21%);
  }
  .bg-r-2-rm-rp {
    transform: translate(369%, -99%);
    width: 25%;
  }
  .bg-l-1-rm-rp {
    width: 53%;
    transform: translate(-80%, -97%);
  }
  .bg-r-1-rm-rp {
    width: 60%;
    transform: translate(117%, -92%);
  }
  .box-road-map {
    width: 80% !important;
  }
  .line-2-rm-rp {
    width: 0.8%;
    transform: translate(1305%, -96%);
  }
}

@media screen and (max-width: 475px) {
  .bg-r-3-rm-rp {
    transform: translate(134%, -21%);
  }
  .img-how-to-play {
    transform: translateY(-40%);
  }

  .border-step {
    width: 230px;
  }
  .text-step-road-map {
    padding-left: 6%;
    padding-right: 20%;
  }
  .text-title-step-road-map {
    padding-top: 3%;
    padding-bottom: 5%;
    font-size: 20px;
    line-height: 20px;
  }
  .text-desc-step-road-map {
    font-size: 12px;
  }
  .text-phrase-step-road-map {
    font-size: 10px;
  }
  .box-content-title {
    font-size: 24px !important;
  }
  .step-text {
    font-size: 20px;
    line-height: 20px;
  }
  .txt-road-map {
    font-size: 14px;
  }
}
@media screen and (max-width: 425px) {
  .container-public,
  .container-private {
    padding-left: 4px;
    padding-right: 4px;
  }

  .container-public .navbar-logo {
    margin-left: 0px;
  }
  .container-private .navbar-logo {
    margin-left: 24px;
  }

  .container-private .card-wrapper {
    padding-left: 57px;
    padding-right: 57px;
  }

  .footer-text {
    font-size: 22.6px !important;
    line-height: 22.6px !important;
  }
  .bg-r-3-rm-rp {
    transform: translate(111%, -47%);
  }
  .bg-table-head-wp {
    width: calc(500% + 1px);
  }
  /* .bg-table-head {
    width: 586%;
  } */
  .table-mint-your-key {
    margin-top: 40%;
  }
  .img-how-to-play {
    transform: unset;
  }
  .box-content {
    transform: unset;
  }
  .bg-r-3-rm-rp {
    width: 35%;
    transform: translate(112%, -47%);
  }
  .line-short-2-rm-rp {
    width: 12%;
  }
  .bg-r-2-rm-rp {
    width: 25%;
    transform: translate(375%, -99%);
  }
  .line-2-rm-rp {
    width: 0.8%;
    transform: translate(1305%, -94%);
  }
  .bg-l-1-rm-rp {
    width: 48%;
    transform: translate(-40%, -102%);
  }
  .line-1-rm-rp {
    width: 0.8%;
    transform: translate(-11000%, -100%);
  }
  .line-short-1-rm-rp {
    width: 11%;
    transform: translate(832%, -59%);
  }
  .bg-r-1-rm-rp {
    width: 47%;
    transform: translate(154%, -107%);
  }
  .home-main-text {
    font-size: 32px;
    line-height: 32px;
  }
  .card-cube-left {
    width: 250px;
  }
  .card-cube-center-bg {
    left: 80px;
  }
  .box-key-rp {
    width: 70%;
  }
  .box-road-map {
    width: 100% !important;
  }
  .border-rp-large {
    display: none;
  }
  .border-rp-small {
    display: unset;
  }
  .step-border-1 {
    top: 3px;
    left: 16px;
    width: 62%;
  }

  .text-step-1 {
    top: 3px;
    left: 0;
    width: 64%;
    height: 6%;
  }

  .box-content {
    display: flex;
    flex-direction: column-reverse !important;
    top: -150px;
  }
  .box-content-item {
    width: 100%;
    padding-top: 30px;
  }
  .box-content-text {
    width: 90%;
  }
  .text-step-3 {
    width: 44%;
    height: 5%;
  }
  .text-step-4 {
    top: 3px;
    left: 0;
    width: 64%;
    height: 6%;
  }

  .text-step-5 {
    top: 3px;
    left: 0;
    width: 64%;
    height: 6%;
  }
  .table-text {
    font-size: 12px;
    line-height: 56px;
  }
  .table-text-head {
    line-height: 32px;
  }
  /* .bg-table-head {
    height: 98px;
  } */
  .display-text {
    display: none;
  }
  .bottom-text-htp {
    width: 100% !important;
  }
  .img-header-wp {
    width: 36%;
    top: -73% !important;
  }
  .col-box-wp-rp {
    width: 100%;
    height: 300px;
  }
  .content-2-wp-rp {
    margin-top: 400px !important;
  }
  .button-white-page {
    /* padding-top: 50px; */
  }
  .col-box-wp-long-rp {
    margin-top: 50px;
  }
  .table-distribution {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .table-distribution-wp {
    margin-bottom: 200px !important;
    margin-top: 200px !important;
  }
  .txt-table-head {
    font-size: 10px;
  }
  .txt-table-body {
    font-size: 10px;
  }
}
@media screen and (max-width: 414px) {
  .bg-r-3-rm-rp {
    transform: translate(158%, -22%);
  }
  .table-text-head-rm {
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  /* .bg-table-head-wp-rp {
      width: 500%;
    } */
  /* .bg-table-head {
    width: 486%;
  } */
  .mb-table-head {
    height: 3px;
  }
  .table-head {
    height: 5rem !important;
  }
  .table-text-head {
    font-size: 11px;
  }

  .text-step3 {
    left: 2px;
  }
  .box-content-title {
    font-weight: 400 !important;
    font-size: 24px !important;
  }
  .box-content-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;

    letter-spacing: 0.05em;
  }
  .border-step {
    width: 190px;
  }

  .box-width-content {
    width: 90% !important;
    height: 50%;
  }
  .box-content {
    width: 96%;
  }
}
@media screen and (max-width: 393px) {
  .text-title-step-road-map {
    padding-bottom: 2%;
  }
}

@media screen and (max-width: 380px) {
  .bg-r-3-rm-rp {
    width: 35%;
    transform: translate(108%, -31%);
  }
  .img-how-to-play {
    transform: unset;
  }

  .box-road-map-8 {
    transform: unset;
    transform: translateX(-6%);
    padding-right: 5%;
    padding-bottom: 20%;
  }
  .bg-l-1-rm-rp {
    width: 42%;
    transform: translate(-29%, -102%);
  }
  .bg-r-2-rm-rp {
    width: 22%;
    transform: translate(409%, -99%);
  }
  .line-2-rm-rp {
    width: 0.7%;
    transform: translate(1405%, -94%);
  }
  .line-1-rm-rp {
    width: 0.7%;
    transform: translate(-12500%, -100%);
  }
  .bg-r-1-rm-rp {
    width: 47%;
    transform: translate(140%, -107%);
  }
  .container-content-title {
    padding-right: 35%;
  }
  .box-key-rp {
    width: 80%;
  }
  .box-road-map {
    width: 100% !important;
  }
}

@media screen and (max-width: 370px) {
  .img-how-to-play {
    transform: translateY(10%);
  }
}

@media screen and (max-width: 360px) {
  .bg-table-head-myk {
    height: 100%;
  }
  .box-road-map-8 {
    padding-left: 5%;
    padding-right: unset;
  }
  .bg-r-3-rm-rp {
    width: 35%;
    transform: translate(-38%, -32%);
  }
  .table-text-rm {
    font-size: 10px;
  }
  .tbl-head-myk {
    padding-top: 10% !important;
  }

  .img-scale {
    transform: scaleX(-1);
  }
  .img-spare-wp {
    position: absolute;
    width: 7%;
    top: -8% !important;
    left: 77%;
  }
  .img-header-wp-small {
    display: unset;
  }
  .img-header-wp-large {
    display: none;
  }
  /* .bg-table-head-wp-rp {
    width: 599%;
  } */
  /* .bg-table-head {
    width: 500%;
  } */
  .btn-white-page {
    height: 30px;
    width: 198px;
  }
  .txt-btn-white-page {
    font-size: 12px;
    line-height: 14.4px;
  }
  .content-3-wp-rp {
    margin-top: 500px !important;
  }
  .content-1-wp {
    margin-top: unset !important;
  }

  .instruction-white-page {
    padding-top: 20%;
  }
  .img-header-wp {
    width: 44%;
    top: -44% !important;
    left: 31%;
  }
  .border-key-road-map {
    height: 300px;
  }
  .box-txt-road-map {
    width: 115%;
  }
  .table-mint-your-key {
    margin-top: 60%;
  }
  .table-text-head {
    line-height: 20px;
  }

  .box-img-step1 {
    transform: translateY(25%);
  }

  .step-border-1 {
    top: 3px;
    left: 16px;
  }
  .text-step-1 {
    top: 4px;
  }
  .box-width-content {
    width: 70% !important;
    height: 50%;
  }
  .box-content-item {
    padding-top: unset;
  }
  .table-text {
    font-size: 11px;
  }
  .box-content {
    top: -120px;
  }
}
@media screen and (max-width: 350px) {
  .img-how-to-play {
    transform: translateY(20%);
  }
}
@media screen and (max-width: 340px) {
  .img-how-to-play {
    transform: translateY(40%);
  }
}
@media screen and (max-width: 320px) {
  .img-how-to-play {
    transform: unset;
  }
  .text-step-right-road-map {
    padding-left: 8%;
    padding-right: 15%;
  }
  .bg-r-3-rm-rp {
    width: 35%;
    transform: translate(-60%, -41%);
  }
  .bg-r-2-rm-rp {
    width: 20%;
    transform: translate(435%, -99%);
  }
  .line-2-rm-rp {
    transform: translate(1405%, -89%);
  }
  .bg-l-1-rm-rp {
    width: 37%;
    transform: translate(-26%, -102%);
  }
  .line-1-rm-rp {
    transform: translate(-12500%, -91%);
  }
  .box-road-map {
    width: 100% !important;
  }
  .box-key-rp {
    width: 90%;
  }
  .table-text {
    font-size: 9px;
  }
  .box-content-text {
    font-size: 12px;
  }
  .box-content-title {
    font-size: 20px !important;
  }
  .box-content {
    top: -95px;
  }
  .col-box-wp-rp {
    height: 200px;
  }
  .img-header-wp {
    top: -110% !important;
    left: 34%;
  }
  .container-btn-wp-rp {
    margin-top: 600px !important;
  }
  .btn-white-page {
    width: 100%;
  }
  .txt-btn-white-page {
    font-size: 12px;
  }
  .content-3-wp-rp {
    margin-top: 600px !important;
  }
  .table-distribution-wp {
    margin-top: 400px !important;
  }
  .table-text {
    font-size: 8px;
  }
}

@media screen and (max-width: 310px) {
  .text-title-step-road-map {
    font-size: 16px;
    line-height: 16px;
  }
  .text-desc-step-road-map {
    font-size: 10px;
  }
  .bg-r-3-rm-rp {
    width: 35%;
    transform: translate(149%, -6%);
  }
  .text-box-content-title {
    font-size: 20px;
    line-height: 20px;
  }
  .box-content-text {
    font-size: 10px;
  }
  .img-how-to-play {
    transform: translateY(27%);
  }
  .text-instruction {
    font-size: 12px;
  }
}

@media screen and (max-width: 280px) {
  .bg-r-3-rm-rp {
    width: 20%;
    transform: translate(-13%, 29%);
  }
  .text-title-step-road-map {
    font-size: 12px;
  }
  .step-border-1 {
    width: 60%;
  }
}
