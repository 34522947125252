.w-410 {
  width: 410px;
}
.input-register {
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: rgba(14, 15, 17, 1);
  color: white;
  width: 440px;
  height: 55px;
  padding-left: 15px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.input-register::placeholder {
  color: white;
}
.input-register:focus {
  outline-color: rgba(255, 214, 0, 1);
}
.input-optional {
  position: relative;
  color: rgba(80, 80, 80, 1);
}
.input-optional-text {
  position: absolute;
  top: 25px;
  left: 110px;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.input-optional-text-2 {
  position: absolute;
  top: 25px;
  left: 85px;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.register-title {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  margin-top: 32px;
  margin-bottom: 28px;
}
.register-button {
  background: rgba(255, 214, 0, 0.1);
  border: 2px solid rgba(255, 214, 0, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  color: rgba(255, 214, 0, 1);
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  width: 440px;
  height: 55px;
  margin-top: 30px;
}

@media screen and (max-width: 500px) {
  .input-register {
    width: 350px;
  }
  .w-410 {
    width: 350px;
  }
}
