.admin-live-nav-text {
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-top: 20px;
}
.admin-no-game-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 116px;
  line-height: 116px;
  color: #ffd600;
}
.admin-live-nav-bar {
  position: relative;
  width: 100%;
  height: 8px;
  margin-top: 8px;
  background: #505050;
}
.admin-bar-active {
  height: 8px;
  position: absolute;
  left: 0;
  background: #ffd600;
  width: 60px;
}
.admin-bar-active-past {
  height: 8px;
  position: absolute;
  left: 80px;
  background: #ffd600;
  width: 70px;
}

.game-container {
  margin-top: 64px;
  width: 100%;
  flex-wrap: wrap;
}
.game-card {
  width: 297px;
  height: 300px;
  background: #000000;
  padding-left: 24px;
  padding-top: 32px;
  cursor: pointer;
}
.game-card-title {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 40px;

  color: #ffd600;
}
.mt-65 {
  margin-top: 65px;
}
.game-table-text {
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #ffffff;
}
.new-game-button {
  background: #ffd600;
  border-radius: 6px;
  width: 181px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 175px;
  color: #0e0f11;
  cursor: pointer;
}
