@import url("./bootstrap.min.css");

body {
  background-color: rgba(14, 15, 17, 1);
  color: white;
}
.font-roobert {
  font-family: Robert;
}
.red-text {
  color: #ff1f1f;
}
.black-text {
  color: #000000;
}
.grey-text {
  color: #9c9c9c;
}
.mt-40 {
  margin-top: 40px;
}
.cursor {
  cursor: pointer;
}
.hover {
  cursor: pointer;
}
.hover:hover {
  color: #ffffff;
}
.w-70 {
  width: 70%;
}
.tb-tr-white-page td {
  font-weight: 500;
}
.container-main {
  padding-right: 96px;
  padding-left: 96px;
  overflow: hidden;
}
.container-main.roadmap {
  background-size: contain;
  background-position: center;
  background-position-y: 940px;
  background-repeat: no-repeat;
}
.container-main-home {
  overflow: hidden;
}
.navbar-logo {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  padding-left: 0;
  cursor: pointer;
  position: relative;
}
.navbar-back {
  left: calc(-16px - 12px);
  position: absolute;
}
.navbar-main {
  padding-top: 32px;
  padding-bottom: 35.7px;
  position: relative;
  z-index: 9999;
}
.navbar-main .dropdown-toggle span {
  margin-right: 4px;
  display: inline-block;
}
.navbar-main .dropdown-toggle {
  font-size: 16px;
  min-width: 100px;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: rgba(255, 214, 0, 1);
  font-weight: 600;
  padding: 0;
}
.navbar-main .dropdown-toggle:focus {
  box-shadow: none !important;
  background-color: transparent;
  color: rgba(255, 214, 0, 1);
  outline: 0;
}
.navbar-main .dropdown-menu {
  background: #000;
  border: 1px solid rgb(255 214 0 / 20%);
  padding: 0;
}
.navbar-main .dropdown-menu a {
  color: rgba(255, 214, 0, 1);
}
.navbar-main .dropdown-menu a:hover {
  background: rgb(255 214 0 / 20%);
  cursor: pointer;
}
.navbar-main .dropdown-toggle::after {
  margin-left: auto;
}
.navbar-light .navbar-brand {
  color: #fff !important;
}
.navbar-light .navbar-toggler {
  background: #ffd600;
}
.btn-connect-wallet {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-family: Robert;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  background-image: url("../assets/connect-wallet-button.png");
  background-size: contain;
  background-position: center;
  width: 178px;
  height: 44.3px;
  background-repeat: no-repeat;
  color: #000;
}
.btn-connect-wallet:before {
  content: "";
  top: -20%;
  left: -10%;
  filter: blur(40px);
  background-color: rgba(255, 214, 0, 0.3);
  width: 120%;
  height: 140%;
  position: absolute;
  z-index: -1;
}
.yellow-text {
  color: rgba(255, 214, 0, 1);
}
.navbar-login {
  color: rgba(255, 214, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 20px;
  background: rgba(255, 214, 0, 0.1);
  border: 2px solid rgba(255, 214, 0, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  width: 151px;
  height: 55px;
  font-family: Robert;
  font-size: 15px;
}
.home-main-text {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 96px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
.home-secondary-text {
  font-family: Robert;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  width: 288px;
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */

  letter-spacing: 0.05em;
}
.min-content-height {
  min-height: calc(100vh - 197px - 142px - 182px);
}
.footer-container {
  margin-top: 182px;
}

.secondary-title-container {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}
.footer-l {
  width: 120px !important;
}
.card-list {
  margin-top: 10px;
  min-height: 331px;
}
.main-card {
  margin-right: 16px;
  height: 350px;
  position: relative;
  width: 288px;
  text-align: center;
  background: #171717;
  border-radius: 8px;
  font-family: MonoSpec;
  border: none;
}
.main-card .card-images {
  border-radius: 8px 8px 0 0;
}
.main-card .centered {
  font-family: MineCraft;
}
.main-card-img {
  border-radius: 8px 8px 0 0;
}
.lvl {
  position: relative;
}
.lvl-text {
  position: absolute;
  top: 17px;
  left: 40px;
  font-size: 16px;
  color: black;
}
.card-text-content {
  padding: 16px;
  font-family: MonoSpec;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.card-tr {
  border-bottom: 20px solid transparent;
}
.card-cube {
  position: absolute;
  right: 0;
  top: 0px;
}
.card-cube-left {
  position: absolute;
  left: 0;
  top: 150px;
}
.card-cube-left-p2 {
  position: absolute;
  left: 200px;
  top: 300px;
}
.card-cube-center-bg {
  position: absolute;
  left: 100px;
  top: 150px;
  width: 75vw;
}
.mt-192 {
  margin-top: 192px;
}

.mobile-only {
  display: none;
}
.info-card {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}
.info-card-title {
  font-size: 48px;
  line-height: 48px;
}
.info-card-explain {
  font-family: Robert;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  width: 75%;
}
.footer-how {
  height: 550px;
  position: relative;
}
.mt-50 {
  margin-top: 50px;
}
.fimg-text-2 {
  height: 80%;
}
.fimg-1 {
  width: 322px;
  position: absolute;
  top: 0;
  left: 0;
}
.fimg-2 {
  width: 322px;
  position: absolute;
  top: 0;
  left: 40%;
}
.fimg-3 {
  width: 322px;
  position: absolute;
  top: 0;
  right: calc(-50% + 130px);
}
.fimg-4 {
  width: 322px;
  position: absolute;
  bottom: -150px;
  right: 0;
}
.fimg-5 {
  width: 322px;
  position: absolute;
  bottom: -120px;
  left: 0;
}
.fimg-6 {
  width: 222px;
  position: absolute;
  top: 45%;
}
.fimg-back {
  position: absolute;
}
.fimg-text {
  position: absolute;
  top: 50%;
  width: 100%;
}
.fimg-text-2 {
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  top: 25%;
  color: black;
}
.black-box-ss {
  display: flex;
  justify-content: center;
  height: 2414px;
  position: relative;
}
.animation-right-ss {
  width: 500px;
  height: 500px;
  display: block;
  position: fixed;
  top: 100px;
}
.upto {
  background: #262628;
  width: 330px;
  padding: 10px;
  font-size: 32px;
  font-family: "Minecraft";
  border-radius: 8px;
}
.title-ft {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 48px;
  font-family: "Minecraft";
  border-radius: 8px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-288 {
  margin-top: 288px;
}
.card2-text {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: rgba(255, 214, 0, 1);
  position: absolute;
  bottom: 150px;
  left: 90px;
  right: 90px;
  z-index: 1;
  opacity: 1;
  transition: 1s;
  top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-text-showcase {
  background-color: rgba(14, 15, 17, 1);
  width: 288px;
  height: 240px;
}
.card-2-m {
  position: relative;
}
.card2-settings {
  transition: 2s;
  opacity: 1;
  z-index: 0;
  width: 288px;
  height: 297px;
}
.cont-key {
  position: relative;
  width: 100%;
}
.rotated {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}
.not-rotated {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.z-m {
  position: relative;
  z-index: 100;
  background-color: black;
}
.key-img {
  z-index: 2;
  position: absolute;
  right: 0px;
  top: 0;
  transition: 1s;
}
.mt-170 {
  margin-top: 170px;
}
.eth-crown {
  opacity: 0;
  transition: 1s;
  position: absolute;
  top: 10px;
}
.mobile-key-ani {
  transition: 0.7s;
  position: absolute;
  top: -15px;
  right: -120px;
}
.mobile-key-text {
  opacity: 1;
  transition: 0.5s;
}
.mobilekey-cover {
  position: relative;
}
.eth-crown-p {
  opacity: 0;
  transition: 1s;
  position: absolute;
  top: 10px;
}
.eth-crown-c {
  padding-top: 50px;
  position: relative;
}

.key-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  opacity: 1;
}
.ms-230 {
  margin-left: 230px;
}
.pt-356 {
  padding-top: 356px;
}
.mt-316 {
  margin-top: 316px;
}
.font-minecraft {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
}
.mt-285 {
  margin-top: 285px;
}
.dotted-line {
  border: 1px dashed #ffd600;
  width: 350px;
}
.pass-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  width: 87px;
  height: 55px;
  color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}
.pass-box.active {
  background: rgba(255, 214, 0, 0.1);
  color: rgba(255, 214, 0, 1);
  border: 0;
}
.mt-240 {
  margin-top: 240px;
}
.black-box-2 {
  background-color: #000000;
  padding: 226px 0px 202px 0px;
  position: relative;
}
.black-box-wrapper {
  max-width: 965px;
}
.sub-title-win {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}
.title-win {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 96px;
}
.mt-48 {
  margin-top: 48px;
}
.join-button {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 125px;
  height: 55px;
  background: #ffd600;
  color: #000000;
  border: 2px solid rgba(255, 214, 0, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 24px;
}
.subss-input {
  width: 340px;
  height: 55px;
  border: 2px solid #202226;
  border-radius: 6px;
  background-color: #ffd600;
  font-weight: 400;
  color: #202226;
  font-size: 16px;
  line-height: 19px;
  padding-left: 20px;
}
.subss-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 340px;
  height: 55px;
  background: #262628;
  color: #ffd600;
  border: 2px solid rgba(255, 214, 0, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 24px;
}

.ft-desc-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */

  text-align: center;
  letter-spacing: 0.05em;

  color: #ffffff;
}
.eth-d-main {
  position: relative;
}
.eth-d {
  position: absolute;
  top: -150px;
}
.roadl {
  max-width: 3000px !important;
}
.subs-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 448px;
  height: 55px;
  background: rgba(255, 214, 0, 0.1);
  border: 2px solid rgba(255, 214, 0, 0.25);
  border-radius: 6px;
  color: #ffd600;
  cursor: pointer;
}
.w-50-w {
  width: 50%;
}
.fa-twitter:hover {
  color: rgba(255, 255, 255, 0.651) !important;
}
.fa-instagram:hover {
  color: rgba(255, 255, 255, 0.651) !important;
}
.fa-discord:hover {
  color: rgba(255, 255, 255, 0.651) !important;
}
.subs-button a {
  color: #ffd600;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.subs-input {
  width: 448px;
  height: 55px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  margin-right: 32px;
  background: #000000;
  padding-left: 20px;
  color: white;
}
.subs-input-group {
  margin-top: 43px;
}
.fs3a {
  margin-top: -300px;
}
.footer-text {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 96px;
}
.mt-36 {
  margin-top: 36px;
}
.mt-42 {
  margin-top: 42px;
}
.footer-labels {
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffd600;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.right-col {
  text-align: right;
}
.mb-51 {
  margin-bottom: 51px;
}
.justify-content-center-f {
  justify-content: center !important;
}
.navbar-user {
  font-family: MineCraft;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
}
.user-avatar {
  margin-left: 10px;
  font-size: 20px;
}
.card-wrapper {
  padding: 0 15px;
  width: 25%;
}
.card-item {
  background-color: #171717;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 50px;
  cursor: pointer;
}
.card-images {
  max-height: 240px;
  min-height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}
.card-img {
  border-radius: 8px 8px 0 0;
}
.card-images h2 {
  margin-bottom: 0;
  font-family: MineCraft;
  font-size: 24px;
  text-align: center;
}
.card-footer {
  padding: 15px;
}
.card-footer-row {
  display: flex;
  justify-content: space-between;
  font-family: MonoSpec;
  font-size: 14px;
  text-transform: uppercase;
}
.card-footer-row:first-child {
  margin-bottom: 14px;
}
.card-wrapper a {
  text-decoration: none;
  color: #ffffff;
}
.main-button {
  background-color: #ffd600;
  color: #0e0f11;
  border: 1px solid rgb(255 214 0 / 25%);
  display: inline-flex;
  font-weight: 600;
  padding: 0 20px;
  border-radius: 6px;
  height: 55px;
  font-family: Robert;
  font-size: 16px;
  line-height: 55px;
}
.button-row .navbar-login {
  background-color: #ffd600;
  color: #0e0f11;
  border: 1px solid rgb(255 214 0 / 25%);
  display: inline-flex;
  font-weight: 600;
}
.Connect-Modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.Connect-Modal .modal-dialog-centered {
  max-width: 288px;
}
.Connect-Modal .modal-content {
  background-color: #000000;
  border: 1px solid rgb(255 214 0 / 20%);
}
.Connect-Modal .modal-body {
  padding: 15px;
}
.Connect-Modal .modal-body h4 {
  font-size: 24px;
  font-family: MineCraft;
  color: #ffffff;
  margin: 6px 0 45px;
}
.button-section .button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 53px;
  border: 1px solid #ffd600;
  color: #ffd600;
  font-size: 16px;
  font-family: Robert;
  font-weight: 600;
  padding: 0 14px;
  background-color: transparent;
  margin-bottom: 16px;
  border-radius: 6px;
}
.modal {
  backdrop-filter: blur(52px);
  background-color: rgb(0 0 0 / 5%);
}
.Connect-Modal .modal-body p {
  font-size: 12px;
  font-family: Robert;
  font-weight: 400;
  line-height: 14.4px;
  margin: 41px 0 5px;
}
.Connect-Modal .modal-body p a {
  color: #ffd600;
  text-decoration: none;
}
.nav-dropdown-item {
  cursor: pointer;
}
.nav-dropdown-item:active {
  background-color: #ffd600 !important;
}
.wallet-connect {
  margin-left: auto;
  /* text-align: center; */
}
.wallet-connect button {
  background-color: transparent;
}
.wallet-connect button:hover,
.wallet-connect.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.wallet-connect button:active {
  background-color: #ffd600 !important;
  border-color: #ffd600 !important;
  color: #000 !important;
  box-shadow: none !important;
}
.closeButton {
  position: absolute;
  right: 0;
  top: -56px;
  cursor: pointer;
}
.back-button {
  cursor: pointer;
  margin: -1px 12px 2px 0;
}
.word_image {
  width: 800px;
}

.account {
  color: #ffd600;
}

.account.in-game {
  display: flex;
}
.account.in-game > div {
  margin-right: 12px;
}
.account.in-game .account-info.username img {
  margin-right: 6px;
}

.share-modal .modal-dialog {
  width: 450px;
}

.counter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.counter-container-c {
  padding-top: 32%;
}
.background-left-counter {
  position: absolute !important;
  left: 0;
  top: -34%;
  width: 13%;
}
.background-right-counter {
  position: absolute !important;
  right: 0;
  top: 0;
  width: 13%;
}
.background-key-2-counter {
  position: absolute !important;
  left: -58px;
  top: -190px;
  width: 33%;
}
.background-key-5-counter {
  position: absolute !important;
  left: 0;
  top: -67px;
  width: 34%;
}
.background-key-6-counter {
  position: absolute !important;
  left: 20%;
  top: -10%;
  width: 25%;
}
.background-key-1-counter {
  position: absolute !important;
  left: 20%;
  bottom: 0;
  width: 30%;
}
.background-key-7-counter {
  position: absolute !important;
  right: 20%;
  bottom: 0;
  width: 25%;
}
.background-key-3-counter {
  position: absolute !important;
  right: 20%;
  top: -2%;
  width: 30%;
}
.background-key-4-counter {
  position: absolute !important;
  right: 0;
  top: 100px;
  width: 25%;
}
.background-coin-counter {
  position: absolute !important;
  right: 50px;
  top: -150px;
  width: 25%;
}
.counter-box {
  margin-top: 20px;
  box-sizing: border-box;
  width: 80%;
  height: 14.5rem;
  background: #0e0f11;
  border: 1px solid #ffd600;
  border-radius: 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.counter-item {
  box-sizing: border-box;
  height: 184px;
  border: 1px solid #ffd600;
  border-radius: 8px;
  justify-content: center;
  background: linear-gradient(180deg, #4a442e 0%, #171717 100%);
}

.counter-text {
  font-style: normal;
  font-family: MineCraft;
  text-align: center;
  color: #ffd600;
}
.tick-flip {
  width: 100% !important;
  margin-left: unset !important;
  margin-right: unset !important;
  min-width: unset !important;
  letter-spacing: unset !important;
  text-indent: unset !important;
}

.tick-flip-panel {
  font-style: normal;
  font-family: MineCraft;
  color: #ffd600 !important;
  background: linear-gradient(180deg, #4a442e 0%, #171717 100%) !important;
  border-bottom: 1px solid #ffd600 !important;
  border-right: 1px solid #ffd600 !important;
}
.tick-flip-card {
  border: 1px solid #ffd600 !important;
  border-radius: 8px !important;
}
.counter-text-size {
  width: 20%;
  font-weight: 400;
  font-size: 8rem;
  letter-spacing: -0.06em !important;
}

.counter-title-size {
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.05em;
}

.counter-title-box {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mint-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.mint-box-top {
  width: 560px;
  height: 96px;
  font-family: MineCraft;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
}

.mint-box-bottom {
  position: relative;
  margin-top: 25px;
  display: flex;
  box-sizing: border-box;
  width: 340px;
  height: 120px;
  background: transparent;
  box-shadow: 0px 0px 120px rgba(255, 214, 0, 0.25);
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.btn-mint {
  width: 100%;
  height: 100%;
}

.test {
  width: 100%;
  height: 100%;
  border: 2px solid #ffd600;
  display: inline-block;
  position: relative;
  background: #171717;
  color: #999999;
  transform: scaleX(-1);
}

.test div {
  transform: scaleX(-1);
}
.test:before,
.test:after {
  height: 25px;
  width: 25px;
  background: #171717;
  position: absolute;
  content: "";
}
.test:before {
  top: 0;
  left: 0;
  border-right: 2px solid #ffd600;
  transform: rotate(49deg) translate(-71%);
}
.test:after {
  bottom: 0;
  right: 0;
  border-left: 2px solid #ffd600;
  transform: rotate(49deg) translate(71%);
}
.tran-x {
  transform: scaleX(-1);
  stroke-width: 2px;
  stroke: #ffd600;
}

.titles {
  stroke: white;
  z-index: 9999999;
  transform: scale(-1, 1);
}
.bottom-img-3d {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  width: 2900px;
  height: 600px;
  transform: scale(1.1);
}

.mint-button-text {
  width: 100%;
  font-family: MineCraft;
  font-style: normal;
  font-weight: 400;
  font-size: 64.8511px;
  letter-spacing: 0.05em;
  color: #ffd600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mint-border1 {
  position: absolute;
  width: 90%;
  height: 3px;
  top: 0;
  left: 0;
  background-color: #ffd600;
}

.mint-border2 {
  position: absolute;
  width: 55%;
  height: 3px;
  top: 0;
  left: -92px;
  background-color: #ffd600;
  transform: rotate(-90deg);
}

.mint-border3 {
  position: absolute;
  width: 93%;
  height: 3px;
  bottom: 0;
  right: 0;
  background-color: #ffd600;
}

.mint-border4 {
  position: absolute;
  width: 47%;
  height: 3px;
  bottom: 0;
  right: -79px;
  background-color: #ffd600;
  transform: rotate(-90deg);
}

.mint-border5 {
  position: absolute;
  bottom: 2px;
  right: 4px;
}

.background-left-counter {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 13%;
}

.new-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
  height: 2.75rem;
  margin-left: 72px;
  position: relative;
}
.new-menu-active {
  position: relative;
  transition: 0.2s;
}
.new-menu-active:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 6px;
  width: 8px;
  height: 8px;
  border: 1px solid #ffd600;
  border-width: 1px 0 0 1px;
  transition: 0.2s;
  opacity: 1;
}
.new-menu-active:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 5px;
  width: 8px;
  height: 8px;
  border: 1px solid #ffd600;
  border-width: 0px 1px 1px 0px;
  transition: 0.2s;
  opacity: 1;
}

.social-footer-s {
  margin-top: 62px;
  border-top: 2px solid #e7f1f898;
  height: 10px;
  padding-bottom: 10px;
}
.mx-4r {
  margin-left: 96px;
  margin-right: 96px;
}

.menu-item {
  height: 100%;
  /* width: 25%; */
  padding-left: 20px;
  padding-right: 20px;
  /* min-width: 105px; */
  font-family: Robert;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  transition: 0.5s;
  position: relative;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item-active {
  color: #ffd600;
}
.menu-item-active::before {
  opacity: 1 !important;
}
.menu-item-active::after {
  opacity: 1 !important;
}
.menu-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  border: 1px solid #ffd600;
  border-width: 1px 0 0 1px;
  transition: 0.5s;
  opacity: 0;
}
.menu-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 8px;
  height: 8px;
  border: 1px solid #ffd600;
  border-width: 0px 1px 1px 0px;
  transition: 0.5s;
  opacity: 0;
}
.menu-item:hover {
  color: #ffd600;
}
.menu-item:hover::after {
  opacity: 1;
}
.menu-item:hover::before {
  opacity: 1;
}

.main-menu-btn {
  display: none;
}
.open-menu {
  display: none;
}
.txt-info-wallet {
  font-family: Robert;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 140px;
}
.txt-info-wallet .account-info {
  position: relative;
  padding: 0 6px;
  min-width: fit-content;
  font-size: 14px;
  /* border-right: 1.5px solid #ffffff; */
}
.account-info.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: auto !important;
  text-align: right;
}
/* .txt-info-wallet .account-info::after { */
.txt-info-wallet .account-info.username::after {
  content: "|";
  position: absolute;
  width: 2px;
  height: 24px;
  right: 0;
}
.txt-info-wallet .account-info:last-child::after {
  content: "";
}
.txt-info-wallet {
  max-width: 230px;
  width: 230px;
}
.txt-info-wallet.loading {
  width: 230px;
}

@media screen and (max-width: 1440px) {
  .background-left-counter {
    top: 35%;
    width: 13%;
  }
  .background-key-2-counter {
    left: -2%;
    top: 22%;
  }
  .background-key-5-counter {
    bottom: 0;
  }
  .background-right-counter {
    top: 35%;
    right: 0;
  }
  .background-coin-counter {
    top: 10%;
  }
  .background-key-4-counter {
    right: 0;
    bottom: 0;
  }
  .background-key-6-counter {
    position: absolute !important;
    left: 20%;
    top: 0;
    width: 25%;
  }
  .background-key-1-counter {
    display: none;
  }
  .background-key-7-counter {
    display: none;
  }
  .coin-bg-mint {
    top: 27%;
    right: 1%;
  }
  .menu-item:before {
    top: 5px;
    left: 9px;
  }
  .menu-item:after {
    bottom: 0;
    right: 7px;
  }
}
@media screen and (max-width: 1279px) {
  .mx-4r {
    margin-left: 143px;
    margin-right: 143px;
  }
  .menu-item {
    padding-left: 12px;
    padding-right: 12px;
  }
  .new-menu {
    margin-left: 24px;
  }
  .new-menu-active:before {
    top: 4px;
    left: -1px;
  }
  .new-menu-active:after {
    bottom: 0;
    right: -3px;
  }
  .menu-item:before {
    top: 0;
    left: 0;
  }
  .menu-item:after {
    bottom: 0;
    right: 0;
  }
}
@media screen and (max-width: 1024px) {
  .home-main-text {
    margin-top: 34px;
  }
  .mx-4r {
    margin-left: 67px;
    margin-right: 67px;
  }
  .counter-text-size {
    font-size: 6rem;
  }
  .counter-box {
    height: 10.5rem;
    border-radius: 1rem;
  }
  .btn-connect-wallet {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    padding-bottom: 4px;
    width: 149px;
    height: 44.3px;
    background-repeat: no-repeat;
    color: #000;
  }
  .new-menu-active:before {
    top: 4px;
    left: -1px;
  }
  .new-menu-active:after {
    bottom: 0;
    right: -3px;
  }
  .menu-item {
    padding-left: 12px;
    padding-right: 12px;
  }
  .menu-item::before {
    top: 9px;
    left: -1px;
  }
  .menu-item::after {
    bottom: 5px;
    right: -1px;
  }
  .txt-info-wallet {
    min-width: 175px;
    max-width: 175px;
    width: 175px;
  }
  .txt-info-wallet.loading {
    width: 175px;
  }
  .btn-mint-menu {
    width: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn-mint-menu img {
    width: 80%;
  }
  .new-menu {
    height: 2.75rem;
    margin-left: 24px;
  }
}

@media screen and (max-width: 970px) {
  .btn-mint-menu {
    justify-content: unset;
  }
  .social-f {
    flex-direction: column;
  }
  .new-menu {
    display: none;
  }
  .box-text-menu-rp {
    padding: 5px 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .box-text-menu-rp:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 10%;
    height: 25%;
    border: 3px solid #ffd600;
    border-width: 3px 0 0 3px;
    transition: 0.5;
    opacity: 0;
  }
  .box-text-menu-rp:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10%;
    height: 25%;
    border: 3px solid #ffd600;
    border-width: 0px 3px 3px 0px;
    transition: 0.5;
    opacity: 0;
  }
  .box-text-menu-active-rp {
    color: #ffd600;
  }

  .box-text-menu-active-rp::before {
    opacity: 1 !important;
  }
  .box-text-menu-active-rp::after {
    opacity: 1 !important;
  }
  .box-text-menu-rp:hover {
    color: #ffd600;
  }
  .box-text-menu-rp:hover::after {
    opacity: 1;
  }
  .box-text-menu-rp:hover::before {
    opacity: 1;
  }
  .menu-item-rp {
    width: 260px;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background: #1e1e1e;
  }
  .menu-item-first-rp {
    position: absolute;
    top: 0;
    left: 55px;
  }
  .menu-item-last-rp {
    height: 100px;
    display: flex;
    position: relative;
    /* overflow: hidden; */
  }
  .menu-item-rp img {
    width: 120%;
    height: 110%;
    padding-right: 50%;
    transform: translate(-3%, -5%);
    position: absolute;
  }
  .nav-tab {
    transition: 0.4s ease;
    transform: translateX(500px);
    z-index: 50;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 70px;
    background: #1e1e1e;
    width: 360px;
    height: 640px;
    padding-left: 5%;
  }
  .nav-tab-open {
    transform: translateX(0);
  }
  .open-menu {
    display: unset;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .btn-open-menu {
    display: flex;
    justify-content: right;
    position: fixed;
    right: 30px;
    top: 20px;
    z-index: 90;
    cursor: pointer;
  }
  .btn-wallet {
    display: none;
  }
  .txt-info-wallet {
    display: flex;
    justify-content: flex-start;
    padding-left: 19px;
  }
}

@media screen and (max-width: 768px) {
  .counter-text-size {
    font-size: 4rem;
  }
  .counter-box {
    height: 7rem;
  }

  .mint-box-bottom {
    height: 100px;
    width: 300px;
  }
  .mint-box-top {
    font-size: 40px;
  }
  .mint-button-text {
    font-size: 40px;
  }
}

@media screen and (max-width: 600px) {
  .menu-item-first-rp {
    top: 20px;
    left: 50px;
  }
}

@media screen and (max-width: 685px) {
  .mint-box-top {
    font-size: 36px;
  }
  .mint-button-text {
    font-size: 36px;
  }
  .mint-box-bottom {
    height: 75px;
    width: 200px;
  }
  .counter-text-size {
    font-size: 34px;
  }
  .counter-box {
    height: 4rem;
    border-radius: 8px;
  }
  .counter-title-size {
    font-size: 12px;
  }
  .btn-open-menu {
    top: auto;
    margin-top: -14px;
  }
}

@media screen and (max-width: 575px) {
  .main-card {
    aspect-ratio: 75 / 97;
    height: auto;
  }
  .main-card .card-images {
    height: 70%;
    max-height: none;
  }
  .main-card .card-text-content {
    height: 30%;
    max-height: none;
  }
  .fimg-text {
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: -80%;
  }
  .fimg-text-2 {
    top: 0%;
  }
  .fimg-1 {
    top: -8%;
    left: calc(-50% + 150px);
  }
  .fimg-2 {
    right: 50%;
    left: 50%;
    transform: translate(-50%, -0%);
  }
  .fimg-3 {
    right: calc(-50% + 130px);
    top: -12%;
  }
  .fimg-4 {
    bottom: 35px;
    left: calc(-50% + 45px);
  }
  .fimg-5 {
    width: 282px;
    bottom: -50px;
    left: auto;
    right: calc(-50% + 150px);
  }
  .fimg-6 {
    top: auto;
    bottom: -40px;
    left: 40px;
    width: 180px;
  }
  .footer-how.discord {
    height: 374px;
  }
  .footer-how.discord > div {
    height: 100%;
  }
  .footer-how.discord > img {
    height: 100%;
  }
}

@media screen and (max-width: 360px) {
  .nav-tab {
    width: 100%;
  }
  .menu-item-first-rp {
    left: 40px;
  }
  .mx-4r {
    margin-left: 24px;
  }
}

.swiper-button-prev {
  color: white !important;
}

.swiper-button-next {
  color: white !important;
}

.enter-bc:hover {
  background: #ffd600 !important;
}
