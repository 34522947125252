.container-main.road-map {
  margin: auto;
}
.container-content-title.v2 {
  margin-bottom: 112px;
}
.container-content-title.v2:before {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../assets/road-map/bg-roadmap.png');
  right: 0;
  width: 330px;
  height: 652px;
  margin-top: -100px;
}
.container-step-road-map.v2 {
  height: auto;
  margin-top: 192px;
}
.road-map-card {
  margin-top: -15%;
}
.text-step-road-map.v2 {
  position: relative;
  background-repeat: repeat-y;
  background-size: contain;
  padding-bottom: 83px;
}
.coin-road-map.v2 {
  display: flex;
  justify-content: center;
}
.step-text-right.v2 {
  top: 0;
  right: 2.5%;
}
.step-text-left.v2 {
  top: 0;
  left: 2.5%;
}
.step-text.v2 {
  font-size: 260%;
}
.text-box-content-title.v2 {
  padding-left: 28px;
  position: absolute;
  bottom: 0;
  width: 50%;
}
.text-box-content-title.v2 p {
  margin: 0;
}
.text-box-content-title.v2 img {
  z-index: -1;
}
.text-step-right-road-map.v2 {
  position: relative;
  padding-bottom: 83px;
  background-repeat: repeat-y;
  background-size: contain;
}

/* bg card shading */
.road-map-card:before {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
}
.road-map-card.phase-1:before {
  background-image: url('../assets/road-map/bg-phase-1.png');
  bottom: calc(-299px + 2%);
  left: calc(-0px - 96px);
  width: 222px;
  max-width: 90%;
  height: 299px;
}
.road-map-card.phase-2:before {
  background-image: url('../assets/road-map/bg-phase-2.png');
  bottom: calc(-244px - 1px);
  left: 32px;
  width: 599px;
  max-width: 90%;
  height: 244px;
}
.road-map-card.phase-3:before {
  background-image: url('../assets/road-map/bg-phase-3.png');
  bottom: calc(-149px - 1px);
  left: 24px;
  width: 298px;
  height: 149px;
  max-width: 48%;
}
.road-map-card.phase-4:before {
  background-image: url('../assets/road-map/bg-phase-4.png');
  bottom: calc(-224px - 32px - 1px);
  right: calc(0px - 16%);
  width: 222px;
  height: 224px;
  max-width: 35%;
}
.road-map-card.phase-5:before {
  background-image: url('../assets/road-map/bg-phase-5.png');
  bottom: calc(-222px - 1px);
  left: 0;
  width: 299px;
  height: 222px;
  max-width: 45%;
}
.road-map-card.phase-6:before {
  background-image: url('../assets/road-map/bg-phase-6.png');
  bottom: calc(-70% - 1px);
  right: calc(-0px - 16%);
  width: 296px;
  height: 299px;
  max-width: 45%;
}

/* ribbon */
.road-map-card .complete:before {
  content: '';
  position: absolute;
  background-image: url('../assets/road-map/road-map-ribbon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.road-map-card .complete:after {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  content: 'COMPLETE';
  color: black;
  position: absolute;
  font-size: 28px;
}
.road-map-card.left .complete:before {
  top: 6px;
  left: 6px;
  width: 167px;
  height: 196px;
}
.road-map-card.left .complete:after {
  top: 18px;
  left: 60px;
  width: 167px;
  height: 196px;
  transform: rotate(310deg);
}
.road-map-card.right .complete:before {
  top: 6px;
  right: 6px;
  width: 167px;
  height: 196px;
  transform: scaleX(-1);
}
.road-map-card.right .complete:after {
  top: 48px;
  right: 36px;
  width: 167px;
  height: 196px;
  transform: rotate(50deg);
}

/* lines */
.line-right {
  margin-top: 20px;
  position: absolute;
}

div.box-road-map.v2.line-connection:after {
  content: '';
  position: absolute;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
}

div.box-road-map.v2.line-from-right-bottom-to-left:after {
  background-image: url('../assets/road-map/line-right-road-map-v2.svg');
  background-position: bottom right;
  bottom: calc(-25% - 24px);
  left: 24px;
  width: 40%;
  height: 25%;
}

div.box-road-map.v2.line-from-left-bottom-to-right:after {
  background-image: url('../assets/road-map/line-left-road-map-v2.svg');
  background-position: bottom left;
  bottom: calc(-25% - 24px);
  right: 24px;
  width: 40%;
  height: 25%;
}

div.box-road-map.v2.line-from-left-right-to-right:after {
  background-image: url('../assets/road-map/line-right-top-road-map-v2.svg');
  background-position: top right;
  bottom: calc(50% - 15% - 24px);
  right: calc(-40% - 24px);
  width: 40%;
  height: 50%;
}

div.box-road-map.v2.line-from-right-left-to-left:after {
  background-image: url('../assets/road-map/line-left-top-road-map-v2.svg');
  background-position: top right;
  bottom: calc(50% - 15% - 24px);
  left: calc(-40% - 24px);
  width: 40%;
  height: 20%;
}

@media screen and (max-width: 1024px) {
  .container-step-road-map.v2 {
    margin-top: calc(192px - 7%);
  }
  .container-content-title.v2 {
    margin-bottom: 72px;
  }
  .text-step-road-map.v2 {
    padding-bottom: 24px;
  }
  .text-step-right-road-map.v2 {
    padding-bottom: 24px;
  }
  .container-content-title.v2:before {
    max-width: 30%;
  }
  /* bg card shading */
  .road-map-card.phase-1:before {
    left: calc(60px - 30%);
    max-width: 30%;
  }
  /* ribbon */
  .road-map-card.left .complete:before {
    width: 119px;
    height: 140px;
    top: 4px;
    left: 4px;
  }
  .road-map-card.right .complete:before {
    width: 119px;
    height: 140px;
    top: 4px;
    right: 4px;
  }
  .road-map-card.left .complete:after {
    top: -8px;
    left: 46px;
  }
  .road-map-card.right .complete:after {
    top: 32px;
    right: 12px;
  }
  .road-map-card .complete:after {
    font-size: 24px;
  }
}
@media screen and (max-width: 541px) {
  .container-road-map .box-title {
    margin-top: 43px;
  }
  .container-step-road-map.v2 {
    margin-top: 0;
  }
  .container-content-title.v2 {
    margin-bottom: 0;
    padding-right: 30%;
  }
  .container-content-title.v2:before {
    background-image: url('../assets/road-map/bg-sm-roadmap.png');
    max-width: 30%;
    margin-top: -80px;
    right: -0%;
  }
  .container-step-road-map.v2 {
    padding-bottom: 240px;
  }
  .step-text.v2 {
    font-size: 160%;
  }
  .step-text-left.v2 {
    top: 0.5%;
  }
  .text-box-content-title.v2 {
    width: 100%;
    margin-bottom: calc(-45% - 80px);
    padding: 0;
  }
  .text-box-content-title.v2 img {
    width: 96px;
    transform: translate(-20%, -50%);
    right: -38px;
  }
  .text-box-content-title.v2 p {
    padding-right: 13%;
  }
  .road-map-card {
    margin-top: 56px;
  }

  /* custom yellow phase number */
  .phase-2 .step-right-road-map {
    display: block !important;
    left: 0;
    right: auto;
    transform: scaleX(-1);
  }
  .phase-4 .step-right-road-map {
    display: block !important;
  }
  .phase-4 .step-text-right {
    text-align: right;
  }
  .phase-6 .step-right-road-map {
    display: block !important;
  }
  .phase-6 .step-text-right {
    text-align: right;
  }

  /* phase card image */
  .phase-1 .coin-road-map.v2 {
    margin-top: 60px;
    width: 130%;
    height: 130%;
  }
  .phase-2 .coin-road-map.v2 {
    margin-top: 180px;
    width: 200%;
    height: 200%;
    margin-left: -50px;
  }
  .phase-3 .coin-road-map.v2 {
    margin-top: 50px;
    width: 115%;
    height: 115%;
  }
  .phase-4 .coin-road-map.v2 {
    margin-top: 80px;
    width: 110%;
    height: 110%;
    margin-left: -50px;
  }
  .phase-5 .coin-road-map.v2 {
    margin-top: 20px;
    width: 110%;
    height: 110%;
  }
  .phase-6 .coin-road-map.v2 {
    margin-top: 40px;
    width: 120%;
    height: 120%;
  }
  .phase-7 .coin-road-map.v2 {
    margin-top: 50px;
    width: 130%;
    height: 130%;
    margin-left: -60px;
  }

  /* ribbon */
  .road-map-card.left .complete:before {
    width: 86px;
    height: 100px;
    top: 5px;
    left: 5px;
  }
  .road-map-card.right .complete:before {
    width: 86px;
    height: 100px;
    top: 5px;
    left: 5px;
  }
  .road-map-card.left .complete:after {
    top: -30px;
    left: 44px;
  }
  .road-map-card.right .complete:after {
    top: -30px;
    left: 44px;
  }
  .road-map-card .complete:after {
    font-size: 18px;
  }
  /* custom ribbon */
  .phase-4.road-map-card.left .complete:before {
    transform: scaleX(-1);
    width: 86px;
    height: 100px;
    top: 5px;
    right: 3px;
    left: auto;
  }
  .phase-4.road-map-card.left .complete:after {
    transform: rotate(-310deg);
    width: 86px;
    height: 100px;
    top: 18px;
    right: 20px;
    left: auto;
  }
  .phase-6.road-map-card.left .complete:before {
    transform: scaleX(-1);
    width: 86px;
    height: 100px;
    top: 5px;
    right: 3px;
    left: auto;
  }
  .phase-6.road-map-card.left .complete:after {
    transform: rotate(-310deg);
    width: 86px;
    height: 100px;
    top: 18px;
    right: 20px;
    left: auto;
  }

  /* custom line */
  div.phase-1.box-road-map.v2.line-connection:after {
    background-image: url('../assets/road-map/line-long-right-to-right-road-map-v2.svg');
    background-position: bottom right;
    left: auto;
    width: 36px;
    height: 386px;
    background-size: contain;
    right: -8px;
    bottom: -180px;
  }
  div.phase-2.box-road-map.v2.line-connection:after,
  div.box-road-map.v2.line-from-right-bottom-to-left:after {
    background-image: url('../assets/road-map/line-right-to-right-road-map-v2.svg');
    background-position: bottom right;
    left: auto;
    width: 36px;
    height: 199px;
    background-size: contain;
    right: -8px;
    bottom: -120px;
  }
  div.phase-3.box-road-map.v2.line-connection:after,
  div.phase-5.box-road-map.v2.line-connection:after {
    background-position: top left;
    bottom: -67px;
    right: auto;
    left: 32px;
    width: 40%;
    height: 80px;
    background-size: 200%;
  }
  div.phase-2.box-road-map.v2.line-connection:after {
    bottom: -160px;
  }
  div.phase-6.box-road-map.v2.line-connection:after {
    bottom: -150px;
  }

  /* bg card shading*/
  .road-map-card.phase-1:before,
  .road-map-card.phase-2:before,
  .road-map-card.phase-3:before,
  .road-map-card.phase-6:before {
    background-image: none;
  }
  .road-map-card.phase-4:before {
    background-image: url('../assets/road-map/bg-sm-phase-4.png');
    left: calc(-8% + 4px);
    right: auto;
    bottom: calc(-8% - 4px);
    height: 90px;
  }
  .road-map-card.phase-5:before {
    background-image: url('../assets/road-map/bg-sm-phase-5.png');
    height: 50px;
    bottom: -50px;
    left: auto;
    right: -30px;
    width: 60px;
  }
}

