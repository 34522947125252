@media screen and (max-width: 1300px) {
  .mt-288 {
    margin-top: 200px !important;
  }
}
@media screen and (max-width: 1250px) {
  .black-box-2 {
    margin-top: 20px;
  }
  .subs-button {
    width: 200px !important;
  }
  .card-wrapper {
    width: 33.333%;
  }
}
@media screen and (max-width: 1199px) {
  .container-main {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 992px) {
  .mb-pd-btn-wp {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 991px) {
  .title-win {
    font-size: 70px;
    line-height: 70px;
  }
  .mobile-only {
    display: unset !important;
  }
  .pc-only {
    display: none !important;
  }
  .mobile-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-crown {
    left: 38px;
  }
  .mt-288 {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 933px) {
  .eth-d {
    width: 100px !important;
    height: 100px !important;
    top: -40px !important;
  }
  .card-cube {
    display: none;
  }
  .mobile-list-d {
    position: relative;
    width: 375px !important;
  }
  .card-list {
    margin-top: 20px !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: initial !important;
  }
  .title-ft {
    font-size: 24px;
  }
  .subss-input-container{
    flex-direction: column;
  }
  .title-ft {
    margin-bottom: 20px;
  }
  .black-box-ss{
    display: none;
  }
  .main-card {
    margin-right: 0px !important;
    margin-bottom: 32px;
    width: 100% !important;
    background: #171717;
    border-radius: 8px;
  }
  .footer-text {
    font-size: 45px !important;
    line-height: 45px !important;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
  .mt-192 {
    margin-top: 92px !important;
  }
  .min-content-height {
    min-height: calc(100vh - 197px - 142px - 160px);
  }
  .footer-container {
    margin-top: 160px !important;
  }
  .float-end {
    text-align: center !important;
  }
  .title-win {
    font-size: 56px !important;
    line-height: 56px !important;
    margin-top: 20px;
  }
  .subs-input-group {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .subs-input {
    width: 300px !important;
    margin-right: 0px !important;
    margin-bottom: 10px !important;
  }
  .black-box-2 {
    padding: 40px !important;
  }
  .mobile-footer {
    /* flex-direction: column; */
    width: 100%;
  }
  .footer-l {
    width: 20% !important;
    padding-right: 5px;
  }
  .img-footer-first {
    width: 20% !important;
    height: 20% !important;
  }
  .img-footer {
    width: 12% !important;
    height: 12% !important;
  }

  .txt-footer {
    width: 20%;
  }
  .card-wrapper {
    width: 50%;
  }
  .banner-section {
    padding: 10px 0 70px;
  }
  .banner-section1 {
    padding: 10px 0 10px;
  }
}
@media screen and (max-width: 592px) {
  .footer-labels {
    font-size: 14px !important;
    line-height: 10px !important;
  }
  .txt-footer {
    font-size: 14px !important;
    line-height: 10px !important;
    text-align: right !important;
  }
}
@media screen and (max-width: 575px) {
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ffd600;
  }

  #no-more-tables td {
    /* Behave like a "row" */
    border: none;
    position: relative;
    /* padding-left: 50%; */
    white-space: normal;
    text-align: center;
  }

  #no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    /* width: 45%; */
    padding-right: 10px;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
  }

  /*
  Label the data
  */
  .tb-body-white-page {
    display: flex !important;
  }

  .tb-tr-white-page {
    width: 50%;
  }
  .mb-txt-box-button-white-page {
    padding-left: 0 !important;
  }
  .mb-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (max-width: 575px) {
  .card-list {
    padding-left: 64px;
    padding-right: 64px;
  }
  .card-list .card-wrapper  {
    width: 100% !important;
    padding-left: 0;
    padding-right: 0;
  }
  .home-main-text {
    font-size: 55px;
    line-height: 55px;
  }
  .card-wrapper {
    width: 100%;
  }
  .navbar-login {
    height: 2px;
    font-size: 10px;
    width: 115px;
  }
  .container-main {
    width: 100vw;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 533px) {
  .footer-labels {
    font-size: 12px !important;
    line-height: 10px !important;
  }
  .txt-footer {
    font-size: 12px !important;
    line-height: 10px !important;
    text-align: right !important;
  }
}
@media screen and (max-width: 473px) {
  .footer-labels {
    font-size: 10px !important;
    line-height: 10px !important;
  }
  .txt-footer {
    font-size: 10px !important;
    line-height: 10px !important;
    text-align: right !important;
  }
}
@media screen and (max-width: 425px) {
  .footer-labels {
    font-size: 8px !important;
    line-height: 10px !important;
  }
  .txt-footer {
    font-size: 8px !important;
    line-height: 10px !important;
  }
  .mt-192 {
    margin-top: 80px !important;
  }
  .min-content-height {
    min-height: calc(100vh - 197px - 142px - 96px);
  }
  .footer-container {
    margin-top: 96px !important;
  }
  .mt-42 {
    margin-top: 18px !important;
  }
  .mb-51 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 352px) {
  .footer-labels {
    font-size: 6px !important;
    line-height: 6px !important;
  }
  .txt-footer {
    font-size: 6px !important;
    line-height: 6px !important;
  }
}
@media screen and (max-width: 320px) {
  .footer-l {
    width: 21% !important;
  }
  .footer-labels {
    font-size: 6px !important;
  }
}
